// get the url from the browser
const url = window.location.href;

// clean the url, remove everything after the domain name
const cleanUrl = url.split("/")[0] + "//" + url.split("/")[2];

const env = {
    REACT_APP_ENV_NAME: "prod",
    REACT_APP_GOOGLE_CLIENT_ID: "345800087528-hnf3j3kk8n0er6c7p97gvci9u6lhaqbl.apps.googleusercontent.com",
    REACT_APP_BACKEND_URL: cleanUrl,
    REACT_APP_PUBLIC_POSTHOG_KEY:
        process.env.REACT_APP_STAGE === "qa" ? "phc_1mM4nxBdvjUyP9Ind6T5ofsdLBGr11C0gbLQPRwwcle" : "phc_tyDbso2qRk9NJrV98w5hCNKraOrCw0ylGKv4AbIRuV9",
    REACT_APP_PUBLIC_POSTHOG_HOST: "https://app.posthog.com",
};

export default env;
