export const TABLE_COLUMNS = [
    {
        dataIndex: "id",
        title: "ID",
    },
    {
        dataIndex: "url",
        title: "URL",
    },
    {
        dataIndex: "name",
        title: "name",
    },
    {
        dataIndex: "title",
        title: "title",
    },
    {
        dataIndex: "company_name",
        title: "company",
    },
    {
        dataIndex: "company_linkedin_url",
        title: "company linkedin url",
    },
    {
        dataIndex: "company_url",
        title: "company website",
    },
    {
        dataIndex: "company_headquarters",
        title: "Company country",
    },
    {
        dataIndex: "country",
        title: "Prospect Country",
    },
    {
        dataIndex: "email",
        title: "email",
    },
    {
        dataIndex: "origin",
        title: "Origin",
    },
    {
        dataIndex: "badges_links",
        title: "Badges Links",
    },
    {
        dataIndex: "badges",
        title: "Badges",
    },
    {
        dataIndex: "automated_trigger",
        title: "Automated Trigger",
    },
    {
        dataIndex: "message",
        title: "Message",
    },
    {
        dataIndex: "message_2",
        title: "Second Message",
    },
    {
        dataIndex: "summary",
        title: "Summary",
    },
    {
        dataIndex: "status",
        title: "Status",
    },
    {
        dataIndex: "exported",
        title: "Exported",
    },
    {
        dataIndex: "deny_reason",
        title: "Deny Reason",
    },
    {
        dataIndex: "updated_at",
        title: "Updated At",
    },
    {
        dataIndex: "created_at",
        title: "Created At",
    },
    {
        dataIndex: "admin_note",
        title: "Admin Note",
    },
    {
        dataIndex: "recommendation_score",
        title: "Score",
        width: 400,
    },
    {
        dataIndex: "scoring_reason",
        title: "Scoring Reason",
    },
    {
        dataIndex: "auto_note",
        title: "Auto Note",
    },
];

export const STATUS_OPTIONS = [
    { label: "Hidden", value: 0, color: "grey" },
    { label: "Pushed", value: 1, color: "blue" },
    { label: "Approved", value: 2, color: "green" },
    { label: "Denied", value: 3, color: "red" },
    { label: "Scheduled", value: 4, color: "orange" },
    { label: "Canceled", value: 5, color: "silver" },
];

export const AUTOMATED_TRIGGER_BUCKET_STATUS_OPTIONS = [
    { label: "Hidden", value: 0, color: "grey" },
    { label: "Canceled", value: 5, color: "silver" },
];

export const ORIGIN_OPTIONS = [
    { label: "Your List", value: "your list", color: "yellow" },
    { label: "Suggested", value: "suggested", color: "purple" },
];

export const BADGE_OPTIONS = [
    {
        value: "TWITTER",
        color: "blue",
    },
    {
        value: "EVENT",
        color: "red",
    },
    {
        value: "PERSONAL INFO",
        color: "cyan",
    },
    {
        value: "COMPANY INFO",
        color: "purple",
    },
    {
        value: "JOB CHANGE",
        color: "yellow",
    },
    {
        value: "TEAM GROWING",
        color: "red",
    },
    {
        value: "RECOMMENDATIONS",
        color: "green",
    },
    {
        value: "CHANGED JOBS",
        color: "orange",
    },
];

export const ADMIN_SETTINGS_COLUMNS = [
    {
        dataIndex: "id",
        title: "ID",
    },
    {
        dataIndex: "config_name",
        title: "Config Name",
    },
    {
        dataIndex: "badges_options",
        title: "Badges Options",
    },
    {
        dataIndex: "admin_badges",
        title: "Admin Badges",
    },
];

export const USER_SETTINGS_COLUMNS = [
    // {
    //     dataIndex: "id",
    //     title: "ID",
    // },
    // {
    //     dataIndex: "user_id",
    //     title: "User ID",
    // },
    // {
    //     dataIndex: "full_name",
    //     title: "Full Name",
    // },
    // {
    //     dataIndex: "company_name",
    //     title: "Company Name",
    // },
    // {
    //     dataIndex: "work_email",
    //     title: "Work Email",
    // },
    // {
    //     dataIndex: "company_website",
    //     title: "Company Website",
    // },
    {
        dataIndex: "auto_messages_active",
        title: "Auto Send Messages",
    },
    {
        dataIndex: "refresh_icp",
        title: "Refresh ICP",
    },
    {
        dataIndex: "ai_reply_generation",
        title: "AI Reply Generation",
    },
    {
        dataIndex: "email_cc_list",
        title: "Email CC Recipients",
    },
    {
        dataIndex: "target_persona_description",
        title: "Target Persona Description",
    },
    {
        dataIndex: "additional_notification_emails",
        title: "Additional Notification Emails",
    },
    {
        dataIndex: "icp_description",
        title: "ICP Description",
    },
    {
        dataIndex: "geographical_targets",
        title: "Geographical Targets",
    },
    {
        dataIndex: "seniority_level",
        title: "Seniority Level",
    },
    {
        dataIndex: "target_company_headcount",
        title: "Target Company Headcount",
    },
    {
        dataIndex: "target_industries",
        title: "Target Industries",
    },
    {
        dataIndex: "target_role_function",
        title: "Target Role Function",
    },
    {
        dataIndex: "target_titles",
        title: "Target Titles",
    },

    {
        dataIndex: "company_blacklist",
        title: "Company Blacklist",
    },
];

export const UNEDITABLE_COLUMNS = ["id", "created_at", "updated_at", "auto_note"];
function getColumnSearchProps(arg0: string) {
    throw new Error("Function not implemented.");
}

export const DENIED_REASONS_MAP = {
    bad_company: "Bad Company",
    bad_industry: "Bad Industry",
    bad_territory: "Bad Country",
    bad_title: "Bad Title",
    other: "Other",
};

export enum DenyReasons {
    bad_company = "bad_company",
    bad_industry = "bad_industry",
    bad_territory = "bad_territory",
    bad_title = "bad_title",
    other = "other",
}

export const connectedEnum = {
    CONNECTED: "connected",
    REQUESTED: "requested",
    NOT_CONNECTED: "not connected",
    UNKNOWN: "unknown",
};
