import axios from "axios";
import env from "config/site.config";

export const authProvider: any = {
    login: async ({ credential }: any) => {
        try {
            const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/api/v1/account_managers/login`, credential);

            console.log("response", response);

            if (response.data && response.data.accessToken) {
                const { accessToken, user } = response.data;

                localStorage.setItem("token", accessToken);
                localStorage.setItem("user", JSON.stringify(user));

                window.location.href = "/";

                return {
                    success: true,
                    redirectTo: "/",
                };
            }
        } catch (error) {
            console.error("Login error:", error);
            window.location.href = "/";
            // redirect to login page with error message
            return {
                success: false,
                redirectTo: "/",
            };
        }

        return {
            success: false,
        };
    },

    logout: async () => {
        try {
            const token = localStorage.getItem("token");
            if (token) {
                await axios.post(`${env.REACT_APP_BACKEND_URL}/api/v1/account_managers/logout`, null, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            console.log("Logout success");
        } catch (error) {
            console.error("Logout error:", error);
        } finally {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        }

        return {
            success: true,
            redirectTo: "/",
        };
    },

    checkAuth: async () => {
        const token = localStorage.getItem("token");

        console.log("Check auth token");

        if (token) {
            return Promise.resolve();
        }

        return Promise.reject();
    },

    getPermissions: async () => {
        // Return permissions or null if not implemented
        return null;
    },

    getIdentity: async () => {
        const user = localStorage.getItem("user");

        if (user) {
            return JSON.parse(user);
        }

        return null;
    },
};
