import React, { useState } from "react";
import axios from "axios";
import { Alert, Button } from "@pankod/refine-antd";
import env from "config/site.config";

const GetEmailButton = ({ lead }: any) => {
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleGetEmail = () => {
        console.log(lead?.url);

        const url = `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/scrape_email`;

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        if (url) {
            setLoading(true);
            axios
                .post(url, { url: lead?.url, lead: lead }, config)
                .then((response) => {
                    console.log(response);
                    const data = response.data;
                    console.log(data);
                    if (data.status === "not found") {
                        setError(true);
                        return;
                    }
                    setLoading(false);
                    setEmail(data?.email);
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                    setLoading(false);
                });
        }
    };
    if (error) return <Alert type="error" message="Cannot get mail for this prospect" showIcon />;

    if (email) return <div>{email}</div>;

    return (
        <div>
            <Button loading={loading} onClick={handleGetEmail}>
                Get Email
            </Button>
        </div>
    );
};

export default GetEmailButton;
