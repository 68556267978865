import { DataProvider, Refine } from "@pankod/refine-core";
import { notificationProvider, ErrorComponent, Layout, Sider } from "@pankod/refine-antd";
import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import "@pankod/refine-antd/dist/reset.css";

import { PostList } from "pages/campaign";
import DashboardPage from "pages/dashboard";
import { Login } from "pages/LoginPage";
import { OrganizationsList } from "pages/organizations";

import env from "config/site.config";
import AdminConfig from "pages/AdminConfig";
import UserSettings from "pages/UserSettings";
import axios from "axios";
import { authProvider } from "pages/LoginPage/authProvider";
import { QueryClient } from "@tanstack/react-query";
import EditMode from "components/EditMode";
import RepliesPage from "pages/TriggersAutomation";
import ExternalTools from "pages/ExternalTools";
import { TemplatesPage } from "pages/TemplatesPage";
import AddTemplatePage from "pages/TemplatesPage/AddTemplatePage";
import EditTemplatePage from "pages/TemplatesPage/EditTemplatePage";
import { UserOutlined, MessageOutlined, SettingOutlined, SelectOutlined, SolutionOutlined, ToolOutlined } from "@ant-design/icons";
import EditLeadsMessage from "components/EditLeadsMessage";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const App: React.FC = () => {
    const queryClient = new QueryClient();
    const apiPrefix = "api/v1";
    const posthog = usePostHog();

    const httpProvider = axios.create({
        baseURL: env.REACT_APP_BACKEND_URL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            user: localStorage.getItem("user")?.toString() || "",
        },
    });

    httpProvider.interceptors.response.use(
        (response) => {
            // If the response is successful, simply return it
            return response;
        },
        (error) => {
            // If you get an error response with status 403, redirect to login
            if (error.response?.status === 403) {
                localStorage.removeItem("token"); // Optionally, clear the token
                window.location.href = "/";
            }
            return Promise.reject(error);
        }
    );

    const originalDataProvider: any = dataProvider(env.REACT_APP_BACKEND_URL, httpProvider);

    const customDataProvider: DataProvider = {
        ...originalDataProvider,
        getList: (params) => {
            params.resource = `${apiPrefix}/${params.resource}`;
            return originalDataProvider.getList(params);
        },
        getMany: (params) => {
            params.resource = `${apiPrefix}/${params.resource}`;
            return originalDataProvider.getMany(params);
        },
        getOne: (params) => {
            params.resource = `${apiPrefix}/${params.resource}`;
            return originalDataProvider.getOne(params);
        },
        create: (params) => {
            params.resource = `${apiPrefix}/${params.resource}`;
            return originalDataProvider.create(params);
        },
        update: (params) => {
            params.resource = `${apiPrefix}/${params.resource}`;
            return originalDataProvider.update(params);
        },
        delete: (params: any) => {
            params.resource = `${apiPrefix}/${params.resource}`;
            return originalDataProvider.delete(params);
        },
    };

    useEffect(() => {
        const user: {
            id: string;
            email: string;
            last_login: string;
            name: string;
            profile_picture: string;
            br_username: any;
            br_password: any;
            super_admin: number;
        }[] = JSON.parse(localStorage.getItem("user") || "[]");
        if (posthog && user.length > 0) {
            const userData = user[0];
            posthog.identify(userData.id, {
                email: userData.email,
                name: userData.name,
                super_admin: userData.super_admin,
                last_login: userData.last_login,
            });
        }
    }, [posthog]);

    return (
        <Refine
            LoginPage={Login}
            dataProvider={customDataProvider}
            authProvider={authProvider}
            routerProvider={routerProvider}
            options={{
                reactQuery: {
                    clientConfig: queryClient,
                },
            }}
            auditLogProvider={{
                get: async ({ resource, meta }) => {
                    const { data } = await dataProvider(env.REACT_APP_BACKEND_URL).getList({
                        resource: "logs",
                        filters: [
                            {
                                field: "resource",
                                operator: "eq",
                                value: resource,
                            },
                            {
                                field: "meta.id",
                                operator: "eq",
                                value: meta?.id,
                            },
                        ],
                    });

                    return data;
                },
                create: (params) => {
                    return dataProvider(env.REACT_APP_BACKEND_URL).create({
                        resource: "logs",
                        variables: params,
                    });
                },
                update: async ({ id, name }) => {
                    const { data } = await dataProvider(env.REACT_APP_BACKEND_URL).update({
                        resource: "logs",
                        id,
                        variables: { name },
                    });
                    return data;
                },
            }}
            resources={[
                {
                    name: "dashboard",
                    list: DashboardPage,
                },
                {
                    name: "campaign",
                    list: PostList,
                    icon: <UserOutlined />,
                    edit: EditLeadsMessage,
                },
                {
                    name: "triggers_automation",
                    list: RepliesPage,
                    icon: <AutoAwesomeIcon />,
                },
                {
                    name: "organizations",
                    list: OrganizationsList,
                },
                {
                    name: "admin",
                    list: AdminConfig,
                    icon: <SettingOutlined />,
                },
                {
                    name: "templates",
                    list: TemplatesPage,
                    edit: EditTemplatePage,
                    create: AddTemplatePage,
                    icon: <SelectOutlined />,
                },
                {
                    name: "user_settings",
                    list: UserSettings,
                    icon: <SolutionOutlined />,
                },
                {
                    name: "external_tools",
                    list: ExternalTools,
                    icon: <ToolOutlined />,
                },
            ]}
            notificationProvider={notificationProvider}
            catchAll={<ErrorComponent />}
            Layout={Layout}
            Sider={() => (
                <Sider
                    render={({ items, dashboard, logout }) => {
                        return (
                            <>
                                <EditMode />
                                {items}
                                {logout}
                            </>
                        );
                    }}
                />
            )}
            Title={() => (
                <div
                    style={{
                        margin: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <img src="https://samplead-webapp-bucket.s3.amazonaws.com/white-logo.png" alt="Samplead" style={{ width: "170px" }} />
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "800",
                            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                            padding: "10px 0px",
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                            background: "linear-gradient(90deg,#ffffff, #ffffff)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Admin Tool
                    </div>
                </div>
            )}
        >
            <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="dashboard" element={<Outlet />} />
                <Route path="campaign" element={<Outlet />} />
                <Route path="admin" element={<Outlet />} />
                <Route path="templates" element={<Outlet />} />
                <Route path="user_settings" element={<Outlet />} />
                <Route path="triggers_automation" element={<Outlet />} />
                <Route path="external_tools" element={<Outlet />} />
            </Routes>
        </Refine>
    );
};

export default App;
