import React, { useEffect, useMemo } from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from "axios";
import Select from "react-select";

import { useCampaignData, useAccountManager } from "context/campaignData";
import { useFilteringData } from "context/filteringData";
import env from "config/site.config";
import { Switch } from "@pankod/refine-antd";

const DashboardPage: React.FC = () => {
    const { setCampaignId, setCampaignData, setUserCampaigns } = useCampaignData();
    const { setAccountManager } = useAccountManager();
    const { filteringData, setFilteringData } = useFilteringData();
    const [accountManagers, setAccountManagers] = React.useState<any>([]);
    const [accountManagersNotificationMap, setAccountManagersNotificationMap] = React.useState<any>({}); // [accountManager: string]: number
    const [selectedAccountManager, setSelectedAccountManager] = React.useState<any>("");
    const [accounts, setAccounts] = React.useState<any>([]);
    const [users, setUsers] = React.useState<any>({});
    const [campaigns, setCampaigns] = React.useState<any>({});
    const [selectedCampaign, setSelectedCampaign] = React.useState<any>("");
    const [showOnlyActiveCampaigns, setShowOnlyActiveCampaigns] = React.useState<boolean>(false);
    const selectAll = useMemo(() => ({ value: "", label: "All" }), []);

    const [selectedUser, setSelectedUser] = React.useState<any>("");
    const [selectedAccount, setSelectedAccount] = React.useState<any>("");

    const history = routerProvider.useHistory();

    const getFilteringData = async () => {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/campaign/filteringData`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            console.log(data);
            if (selectedUser) {
                const obj = {
                    [selectedUser]: users[selectedUser],
                };
                const campaigns = campaignsFromUsers(obj);
                console.log("campaigns", campaigns);
                setCampaigns(campaigns);
            }

            setFilteringData(data);

            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    useEffect(() => {
        handleToggleShowActiveOnly();
    }, [showOnlyActiveCampaigns]);

    const handleToggleShowActiveOnly = async () => {
        if (showOnlyActiveCampaigns) {
            const filteredCampaigns = Object.fromEntries(Object.entries(campaigns)?.filter((campaign: any) => campaign[1].active));
            setCampaigns(filteredCampaigns);
        } else {
            // const data = await getFilteringData();
            // if (!data) return;
            if (selectedUser) {
                const obj = {
                    [selectedUser]: users[selectedUser],
                };
                const campaigns = campaignsFromUsers(obj);
                console.log("campaigns", campaigns);
                setCampaigns(campaigns);

                return;
            }
        }
    };

    const campaignsFromUsers = (users: any) => {
        const campaigns: { [key: string]: { [key: string]: string } } = {};
        Object.entries(users).forEach(([userId, user]: [string, any]) => {
            if (!user.campaigns) return;
            for (const [id, name, active] of user.campaigns) {
                campaigns[id] = {
                    name,
                    active,
                    userId,
                };
            }
        });

        return campaigns;
    };

    const defaultFilteringData = (paramsNotFiltered: any = {}) => {
        const [accountManagers, accounts, users, campaigns] = Object.entries(filteringData).reduce(
            (acc: any, [accountManager, accountManagerData]: any) => {
                acc[0].add(accountManager);
                acc[1] = new Set([...acc[1], ...Object.keys(accountManagerData)]);
                const users = Object.fromEntries(Object.values(accountManagerData).flatMap((account: any) => Object.entries(account)));
                acc[2] = { ...acc[2], ...users };
                acc[3] = { ...acc[3], ...campaignsFromUsers(users) };
                return acc;
            },
            [new Set(), new Set(), {}, {}]
        );
        if (!paramsNotFiltered.accountManagers) setAccountManagers([...accountManagers]);
        if (!paramsNotFiltered.accounts) setAccounts([...accounts]);
        if (!paramsNotFiltered.users) setUsers(users);
        if (!paramsNotFiltered.campaigns) setCampaigns(campaigns);
    };

    const handleSelectAccountManager = (e: any) => {
        setSelectedAccountManager(e.value);
        setSelectedCampaign("");
        if (e.value === "") {
            defaultFilteringData({ accountManagers: true });
            setAccountManager("");
            return;
        }
        console.log("setting account manager to: ", e.value);

        setAccountManager(e.value);
        setAccounts(Object.keys(filteringData[e.value]));
        const users = Object.fromEntries(Object.values(filteringData[e.value]).flatMap((account: any) => Object.entries(account)));
        setUsers(users);
        setCampaigns(campaignsFromUsers(users));
    };

    const handleSelectAccount = (e: any) => {
        setSelectedCampaign("");
        if (e.value === "") {
            defaultFilteringData({
                accountManagers: true,
                accounts: true,
            });
            return;
        }
        let accountManager = selectedAccountManager;
        if (!accountManager || !filteringData[accountManager][e.value]) {
            accountManager = Object.entries(filteringData).find(([, accountManagerData]: any) => accountManagerData[e.value]);
            if (accountManager) {
                accountManager = accountManager[0];
                setSelectedAccountManager(accountManager);
            }
        }
        const users = Object.fromEntries(Object.entries(filteringData[accountManager][e.value]));
        setUsers(users);
        setSelectedAccount(e.value);
        setCampaigns(campaignsFromUsers(users));
    };

    const handleSelectUser = (e: any) => {
        setSelectedCampaign("");
        if (e.value === "") {
            defaultFilteringData({
                accountManagers: true,
                accounts: true,
                users: true,
            });
        }
        setSelectedUser(e.value);
        setCampaigns(campaignsFromUsers({ [e.value]: users[e.value] }));
    };

    const handleSelectCampaign = (e: any) => {
        setSelectedCampaign(e.value);
    };

    // for each account manager, get notification count , use promise.all

    const getNotificationCount = async () => {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/replies/all/notificationCount`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            console.log("notification count: ", data);

            setAccountManagersNotificationMap(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getNotificationCount();
    }, [filteringData]);

    useEffect(() => {
        getFilteringData();
    }, []);

    useEffect(() => {
        if (Object.keys(filteringData).length === 0) return;
        defaultFilteringData();
    }, [filteringData]);

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%" }}>
            <h1>Dashboard</h1>
            <div>
                <Select
                    defaultValue={{ value: "", label: "Select Account Manager" }}
                    onChange={handleSelectAccountManager}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                            {label}
                            {accountManagersNotificationMap[value] > 0 && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        backgroundColor: "red",
                                        color: "white",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "12px",
                                    }}
                                >
                                    {accountManagersNotificationMap[value]}
                                </div>
                            )}
                        </div>
                    )}
                    options={[selectAll, ...accountManagers.map((accountManager: any) => ({ value: accountManager, label: accountManager }))]}
                />

                <Select
                    defaultValue={{ value: "", label: "Select Account" }}
                    onChange={handleSelectAccount}
                    options={[selectAll, ...accounts.map((account: any) => ({ value: account, label: account }))]}
                />

                <Select
                    defaultValue={{ value: "", label: "Select User" }}
                    onChange={handleSelectUser}
                    options={[
                        selectAll,
                        ...Object.entries(users).map(([id, details]: any) => ({
                            value: id,
                            label: details.name,
                            image: details.image_url,
                        })),
                    ]}
                    formatOptionLabel={(user) => (
                        <div>
                            {/*@ts-ignore*/}
                            {user.image && <img style={{ width: "30px", borderRadius: "50%", marginRight: "10px" }} src={user.image} alt="" />}
                            <span>{user.label}</span>
                        </div>
                    )}
                />
                <Select
                    defaultValue={{ value: "", label: "Select Campaign" }}
                    onChange={handleSelectCampaign}
                    options={Object.entries(campaigns).map(([id, data]: any) => ({ value: id, label: data.name }))}
                />
            </div>
            {/* antd toggle for showActiveCampaigns*/}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    gap: "10px",
                }}
            >
                <span>{"Show only campaigns that are active in Expandi"}</span>

                <Switch
                    unCheckedChildren="Off"
                    checkedChildren="On"
                    checked={showOnlyActiveCampaigns}
                    onChange={() => setShowOnlyActiveCampaigns(!showOnlyActiveCampaigns)}
                />
            </div>

            <button
                style={{ marginTop: "20px" }}
                onClick={() => {
                    if (selectedCampaign === "") return;
                    setCampaignId(selectedCampaign);
                    setCampaignData(campaigns[selectedCampaign]);
                    const userId = campaigns[selectedCampaign].userId;
                    setUserCampaigns(campaignsFromUsers({ [userId]: users[userId] }));
                    console.log(users);
                    console.log(campaigns);

                    history.push("/campaign");
                }}
            >
                Go to Campaign
            </button>
        </div>
    );
};

export default DashboardPage;
