import { Form, Button, Input } from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import axios, { AxiosError } from "axios";
import { useState, useEffect, useMemo, SetStateAction, useCallback } from "react";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";

import { TABLE_COLUMNS, STATUS_OPTIONS, ORIGIN_OPTIONS, connectedEnum } from "utils/constants";
import { useCampaignData } from "context/campaignData";
import { Select, Modal, Tag, notification } from "antd";

import env from "config/site.config";
import { getUserSettings, isConnected } from "utils/api";
import { debounce } from "lodash";
import { ILead } from "interfaces";
import posthog from "posthog-js";

const CreateNewLeadForm: React.FC<{
    badgesOptions: {
        templates: any;
        value: string;
        color: string;
        message_templates: [];
        trigger_id: string;
        activeOnProfile: boolean;
    }[];
}> = ({ badgesOptions }) => {
    const { campaignId } = useCampaignData();
    const [textAreasValues, setTextAreasValues] = useState({ message: "", message_2: "", summary: "" });
    const [form] = Form.useForm();
    const [url, setUrl] = useState(""); // State to manage the URL separately

    const initialValues = {
        name: "",
        title: "",
        email: "",
        phone: "",
        campaign_id: campaignId,
        status: 0,
        origin: "",
        badges: [],
        badges_links: [],
        summary: "",
        message: "",
        message_2: "",
        country: "",
        company_country: "",
        extra: "",
        company_name: "",
        company_linkedin_url: "",
        company_url: "",
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMainModalOpen, setMainModalOpen] = useState(false);
    const { Option } = Select;
    const [badgesObjects, setBadgesObjects] = useState<any[]>([]);
    const [lastValueLength, setLastValueLength] = useState(0);
    const [loadingScrape, setLoadingScrape] = useState(false);
    const [tempBadgeObject, setTempBadgeObject] = useState<any>({ link: "", badge: null });
    const [modal, contextHolder] = Modal.useModal();
    const [relevantTemplates, setRelevantTemplates] = useState<any[]>([]);
    const [campaignIdToUse, setCampaignIdToUse] = useState<string>(campaignId);
    const { open } = useNotification();

    const EXCLUDED_COLUMNS = new Set([
        "id",
        "created_at",
        "updated_at",
        "url",
        "deny_reason",
        "admin_note",
        "exported",
        "auto_note",
        "ai_recommended",
        "recommendation_score",
        "scoring_reason",
        "automated_trigger",
        // "message",
        // "message_2",
    ]); // url rendered separately
    const tableColumns = useMemo(() => {
        console.log(
            "tableColumns",
            TABLE_COLUMNS.filter((column) => !EXCLUDED_COLUMNS.has(column.dataIndex))
        );
        return TABLE_COLUMNS.filter((column) => !EXCLUDED_COLUMNS.has(column.dataIndex));
    }, []);

    useEffect(() => {
        const badgesWithoutLinks = form.getFieldValue("badges");

        const relevantTemplates = getAllTemplates(badgesWithoutLinks, {
            name: form.getFieldValue("name"),
            company_name: form.getFieldValue("company_name"),
        });
        console.log("relevantTemplates", relevantTemplates);

        setRelevantTemplates(relevantTemplates);
    }, [form.getFieldValue("badges"), badgesObjects]);

    const debouncedSetUrl = useCallback(
        debounce((newValue) => {
            setUrl(newValue);
        }, 300),
        []
    ); // 300ms debounce time

    const resetFormFields = () => {
        form.resetFields(); // Reset all fields
        form.setFieldsValue({ ...initialValues, url }); // Restore the URL in the form
    };

    useEffect(() => {
        resetFormFields();
    }, [url, form]);

    const handleUrlChange = (event: { persist: () => void; target: { value: any } }) => {
        event.persist(); // Persist the event to access asynchronously
        debouncedSetUrl(event.target.value);
    };

    const getAllTemplates = (badges: any, record: any): string[] => {
        const badgeArray = badgesOptions.filter((b) => badges?.includes(b.value));
        console.log("badgeArray", badgeArray);

        const templates = badgeArray
            .map((badge: any) => {
                const badgeOption = badgesOptions.find((b) => b.value === badge.value);
                if (!badgeOption) return;

                const message_templates = badgeOption?.templates;
                console.log("message_templates", message_templates);

                const editedMessageTemplates = message_templates?.map((m: any) => {
                    if (m?.type !== 0) {
                        return;
                    }
                    let template = m.message;

                    const first_name = record.name.split(" ")[0];
                    template = template.replace("{first_name}", first_name);
                    template = template.replace("{company_name}", record.company_name);
                    return template;
                });

                return editedMessageTemplates;
            })
            .filter((t: any) => t);

        // convert from array of arrays to array of strings

        const result = templates.flat().filter((t: string) => t);

        console.log("result", result);

        return result.filter((t: any) => t);
    };

    const handleScrapeLead = async (url: any) => {
        setLoadingScrape(true);

        try {
            if (!url) return;
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/scrape`,
                { url, campaignId: campaignId },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
            console.log(data);
            if (!data) return;

            const issues = [];
            if (data.isBlacklisted) {
                issues.push("Company is blacklisted");
            }

            if (data.isDuplicatedProspect) {
                issues.push("This prospect already exists for a different profile in the organization");
            }
            if (data?.companyHeadcountDoesntMatchICPres?.doesntMatch) {
                issues.push(
                    `Company headcount is not in the ICP (${
                        data?.companyHeadcountDoesntMatchICPres?.headcount
                    } is not in ${data?.companyHeadcountDoesntMatchICPres?.headcountRanges.join(", ")})`
                );
            }
            if (data.companyCountryDoesntMatchICPres) {
                issues.push("Company location is not in the ICP");
            }
            if (data.wasCompanySuggestedInPastTimeRes) {
                issues.push("Company was suggested in the past 2 weeks");
            }
            if (data.wasCompanySuggestedXTimesRes) {
                issues.push("Company has already been suggested 4 times");
            }

            if (issues.length > 0) {
                posthog.capture("Prospect creation issue", {
                    issues: issues,
                    linkedinUrl: url,
                    campaignId: campaignId,
                });
                Modal.confirm({
                    title: "Prospect has the following issues:",
                    content: (
                        <div>
                            <ul
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                {issues.map((issue, index) => (
                                    <li key={index}>{issue}</li>
                                ))}
                            </ul>
                        </div>
                    ),
                    okText: "Edit anyway",
                    cancelText: "Discard",
                    onOk: () => {
                        // Proceed with editing the prospect
                        populateFormWithLeadData(data.lead);
                    },
                    onCancel: () => {
                        // Discard the prospect
                        resetFormFields();
                    },
                });
            } else {
                // If no issues, proceed with populating the form
                populateFormWithLeadData(data.lead);
            }

            setLoadingScrape(false);
        } catch (error) {
            setLoadingScrape(false);
            resetFormFields();
            console.log("scrape error: ", error);
            open?.({
                type: "error",
                message: "Scrape failed",
                description: "Please check the URL and try again or contact support",
                key: uuidv4().toString(),
            });
        }
    };

    const populateFormWithLeadData = (lead: ILead) => {
        const company_name = lead.company?.name || lead.user_data?.company_name;
        const company_linkedin_url = lead.company?.linkedin_url || lead.user_data?.company_url;
        const company_website = lead.company?.website || lead.user_data?.company_website;

        if (!company_name || !company_linkedin_url) {
            open?.({
                type: "error",
                message: "Company name or linkedin url is missing",
                description: "Please check for the company name and linkedin url of the prospect",
                key: uuidv4().toString(),
            });
        }

        form.setFieldsValue({
            ...form.getFieldsValue(),
            url: lead.prospect?.linkedin_url || lead.user_data?.user_url,
            name: lead.prospect?.name || lead.name,
            title: lead.prospect?.position || lead.title,
            campaign_id: campaignId,
            status: 0,
            country: lead.prospect?.country || lead.country,
            company_country: lead.company?.country || lead.user_data?.company_country,
            company_name: company_name,
            company_linkedin_url: company_linkedin_url,
            company_url: company_website,
            extra: lead.extra || lead.user_data,
        });
    };
    const blacklistedWarning = () => {
        Modal.warning({
            title: "Blacklisted company",
            content: "This lead's company is blacklisted",
        });
    };

    const duplicatedProspectWarning = () => {
        Modal.warning({
            title: "Duplicated prospect",
            content: "This lead already exists for this organization",
        });
    };

    const connectedNotification = () => {
        Modal.info({
            title: "This lead is already connected",
            content: "It will be moved to default messenger campaign",
        });
    };

    const disabledTriggerWarning = (trigger_name: string) => {
        Modal.warning({
            title: `${trigger_name} trigger is turned off for this profile`,
            // show Remove Trigger and Add anyways button
            okText: "Remove Trigger",
            okButtonProps: { danger: true },
            okType: "primary",
            cancelText: "Add Anyways",
            okCancel: true,
            onCancel: () => {
                return;
            },
            onOk: () => {
                removeSpecificBadge(trigger_name);
            },
        });
    };

    const checkScrapedForBlacklisted = async (userSettings: any, lead: any) => {
        if (!userSettings?.company_blacklist) return;

        const blacklistCompanies = userSettings?.company_blacklist;
        const leadCompany = lead?.company?.name || lead?.user_data?.company_name;
        console.log("lead company", leadCompany);

        if (!leadCompany) return;

        console.log("blacklistCompanies", blacklistCompanies);

        const BLACKLISTED_TAG = "BLACKLIST";

        // normalize company name and blacklist company name
        // normalize by removing all spaces
        const normalizedCompanyName = leadCompany?.replace(/\s/g, "")?.toLowerCase();

        console.log("normalizedCompanyName", normalizedCompanyName);
        console.log("blacklistCompanies", blacklistCompanies);

        if (blacklistCompanies?.has(normalizedCompanyName)) {
            blacklistedWarning();
        }
    };

    const handleCreateLead = async (lead: any) => {
        try {
            open?.({
                type: "progress",
                message: "Lead is being uploaded",
                description: "Lead is added to the database",
                key: uuidv4().toString(),
            });
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/create`,
                { lead, campaignId: campaignIdToUse },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
            open?.({
                type: "success",
                message: "Lead created",
                description: `Lead was added to the database ${campaignIdToUse === campaignId ? "successfully" : "to default messenger campaign"}`,
                key: uuidv4().toString(),
            });
            form.resetFields();
            setTextAreasValues({ message: "", message_2: "", summary: "" });
            setBadgesObjects([]);
            window.location.reload();

            console.log(data);
        } catch (error) {
            const axiosError = error as AxiosError;
            resetFormFields();
            open?.({
                type: "error",
                message: axiosError?.response?.data.message,
                description: "Lead upload failed",
                key: uuidv4().toString(),
            });
        }
    };

    const removeSpecificBadge = (badge: string) => {
        setBadgesObjects(badgesObjects.filter((badgeObject) => badgeObject.badge !== badge));
    };

    const handleAddLink = (value: any) => {
        console.log(`selected ${value}`);
        setLastValueLength(value.length);
        if (!tempBadgeObject?.link || !tempBadgeObject?.badge) {
            open?.({
                type: "error",
                message: "Invalid Badge",
                description: "Please select a badge for the link",
                key: uuidv4().toString(),
            });
            return;
        }

        const badgeObject = badgesOptions.find((badge) => badge.value === tempBadgeObject.badge);

        console.log("badgeObject", badgeObject);
        // if badgeObject.activeOnProfile is false, show warning
        if (badgeObject && !badgeObject.activeOnProfile) {
            disabledTriggerWarning(badgeObject.value);
        }

        setBadgesObjects([...badgesObjects, tempBadgeObject]);

        badgesObjects.map((badgeObject) => {
            // if value is not in badgesObjects, remove it
            const link = badgeObject.link;

            if (!value.includes(badgeObject.link)) {
                setBadgesObjects(badgesObjects.filter((badgeObject) => badgeObject.link !== link));
            }
        });
        if (value.length > lastValueLength) {
            setIsModalOpen(true);
        }
    };

    const handleAddBadge = (value: string) => {
        console.log(`badgeObjects  `, badgesObjects);
        console.log(`tempBadgeObject  `, tempBadgeObject);

        if (value && badgesObjects.length > 0) {
            // add badge to last badgesObjects
            badgesObjects[badgesObjects.length - 1].badge = value;
            setBadgesObjects([...badgesObjects]);
        }
    };

    const handleModalOk = () => {
        // check that a valid badge is selected for each link
        const valid = badgesObjects.every((badgeObject) => badgeObject.badge !== null);
        if (!valid) {
            return;
        }
        console.log(badgesObjects);
        const badgeLinks = badgesObjects.map((badgeObject) => badgeObject.link);
        const badges = badgesObjects.map((badgeObject) => badgeObject.badge);

        form.setFieldValue("badges_links", badgeLinks);
        form.setFieldValue("badges", badges);

        modal.confirm({
            title: "Are you sure you want to create this lead?",
        });
        setIsModalOpen(false);
    };

    useEffect(() => {
        console.log("badgesObjects", badgesObjects);
        form.setFieldValue(
            "badges_links",
            badgesObjects.map((badgeObject) => badgeObject.link)
        );
        form.setFieldValue(
            "badges",
            badgesObjects.map((badgeObject) => badgeObject.badge)
        );
    }, [badgesObjects]);

    const handleRemoveLastBadge = () => {
        //remove last obj if there is more than one
        if (badgesObjects.length > 0) setBadgesObjects([...badgesObjects.slice(0, badgesObjects.length - 1)]);
    };

    const handleModalCancel = () => {
        //remove last obj
        setBadgesObjects([...badgesObjects.slice(0, badgesObjects.length - 1)]);
        setIsModalOpen(false);
    };

    const handleChangeOrigin = (value: string) => {
        form.setFieldValue("origin", value);
    };

    function checkIfSuperAdmin() {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        console.log("user", user, user?.[0]?.super_admin);
        return !!user?.[0]?.super_admin;
    }

    async function requestRecordingStatus() {
        const isSuperAdmin = checkIfSuperAdmin();
        if (isSuperAdmin) {
            return true;
        }
        // Dispatch event to get recording state
        window.dispatchEvent(new CustomEvent("MyCustomEventForRecordingState"));

        // Listen for the response
        const recording = await new Promise((resolve) => {
            open?.({
                type: "progress",
                message: "Checking recording status",
                description: "Checking if recording is on",
                key: uuidv4().toString(),
            });
            const timeout = setTimeout(() => {
                window.removeEventListener("message", () => {});
                resolve(false);
            }, 1000);
            window.addEventListener("message", (event) => {
                if (event.source === window && event.data.type === "RecordingStateFromExtension") {
                    console.log("Recording State:", event.data.data);
                    window.removeEventListener("message", () => {});
                    clearTimeout(timeout);
                    resolve(event.data.data);
                }
            });
        });
        if (!recording) {
            open?.({
                type: "error",
                description: "No recording found",
                message: "Please start recording or download the latest version of the extension",
                key: uuidv4().toString(),
            });
            throw new Error("No recording found");
        } else {
            open?.({
                type: "success",
                description: "Recording found",
                message: "Recording is on",
                key: uuidv4().toString(),
            });
        }
        return recording;
    }

    return (
        <>
            <Button
                onClick={async () => {
                    await requestRecordingStatus();
                    setMainModalOpen(true);
                }}
            >
                Create New Lead
            </Button>
            <Modal
                title="Create a new lead"
                open={isMainModalOpen}
                // onOk={handleMainModalOk}
                footer={null}
                onCancel={() => {
                    setUrl("");
                    resetFormFields();
                    setMainModalOpen(false);
                }}
            >
                <Form
                    form={form}
                    initialValues={initialValues}
                    layout="horizontal"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                    onFinish={async (values) => {
                        await requestRecordingStatus();
                        handleCreateLead(values);
                        setMainModalOpen(false);
                    }}
                >
                    <Form.Item name="url" label="URL">
                        <Input.Search
                            placeholder="Paste URL here"
                            enterButton="Scrape"
                            size="large"
                            value={url}
                            onChange={handleUrlChange}
                            onSearch={handleScrapeLead}
                            loading={loadingScrape}
                        />
                    </Form.Item>
                    {tableColumns.map(
                        (column) =>
                            column &&
                            column.dataIndex !== "status" && (
                                <Form.Item
                                    key={column.dataIndex}
                                    name={column.dataIndex}
                                    label={column.title}
                                    rules={[
                                        {
                                            required:
                                                column.dataIndex === "email" ||
                                                column.dataIndex === "badges" ||
                                                column.dataIndex === "message" ||
                                                column.dataIndex === "message_2" ||
                                                column.dataIndex === "summary" ||
                                                column.dataIndex === "company_url" ||
                                                column.dataIndex === "company_headquarters"
                                                    ? false
                                                    : true,
                                            message: `Please input ${column.title}!`,
                                        },
                                    ]}
                                >
                                    {column.dataIndex === "badges" ? (
                                        <div>
                                            {badgesObjects?.length > 0 ? (
                                                badgesObjects?.map((obj: any) => (
                                                    <Tag
                                                        style={{ margin: "10px 10px" }}
                                                        color={!obj?.link || obj?.link?.length === 0 ? "red" : "blue"}
                                                        key={obj.badge}
                                                    >
                                                        <a target="_blank" href={obj?.link}>
                                                            {obj.badge}
                                                        </a>
                                                    </Tag>
                                                ))
                                            ) : (
                                                <div>No Badges</div>
                                            )}
                                            <div style={{ margin: "10px" }}>
                                                {badgesObjects?.length > 0 && <Button onClick={handleRemoveLastBadge}>Remove Badge</Button>}
                                            </div>
                                        </div>
                                    ) : column.dataIndex === "message" || column.dataIndex === "message_2" || column.dataIndex === "summary" ? ( //                 console.log("value", value); //             onChange={(value) => { //             placeholder="Select template" //             style={{ width: "100%" }} //         <Select //     {column.dataIndex === "message" && ( // <GrammarlyEditorPlugin clientId="client_Ehj8QGDBcNsyKvsa12kPiJ">
                                        <div>
                                            {column.dataIndex === "message" && (
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Select template"
                                                    onChange={(value) => {
                                                        console.log("value", value);
                                                        setTextAreasValues((prev) => {
                                                            return {
                                                                ...prev,
                                                                [column.dataIndex]: value,
                                                            };
                                                        });
                                                        form.setFieldValue("message", value);
                                                    }}
                                                >
                                                    {relevantTemplates?.map((item) => {
                                                        console.log("item", item);

                                                        return (
                                                            <Select.Option key={item} value={item}>
                                                                {item}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                            <textarea
                                                value={textAreasValues[column.dataIndex]}
                                                onChange={(e) => {
                                                    setTextAreasValues((prev) => {
                                                        return {
                                                            ...prev,
                                                            [column.dataIndex]: e.target.value,
                                                        };
                                                    });
                                                    form.setFieldValue(column.dataIndex, e.target.value);
                                                }}
                                                style={{ width: "100%", height: "100px" }}
                                            />
                                        </div>
                                    ) : // </GrammarlyEditorPlugin>
                                    column.dataIndex === "badges_links" ? (
                                        <Form.Item>
                                            <Input.Group compact>
                                                <Form.Item
                                                    name={["address", "province"]}
                                                    style={{ width: "40%" }}
                                                    rules={[{ required: true, message: "Province is required" }]}
                                                >
                                                    <ReactSelect
                                                        placeholder="Select Badge"
                                                        onChange={(e: any) =>
                                                            setTempBadgeObject({
                                                                ...tempBadgeObject,
                                                                badge: e.value,
                                                            })
                                                        }
                                                        options={badgesOptions?.map((option) => ({
                                                            value: option.value,
                                                            label: option.value,
                                                            color: option.color,
                                                        }))}
                                                        className="badges-links-select"
                                                        formatOptionLabel={(badgeLink) => (
                                                            <Tag key={badgeLink.value} color={badgeLink.color} style={{ margin: 0 }}>
                                                                {badgeLink.value}
                                                            </Tag>
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item name={["link"]} noStyle rules={[{ required: true, message: "Link is required" }]}>
                                                    <Input
                                                        onChange={(e) => setTempBadgeObject({ ...tempBadgeObject, link: e.target.value })}
                                                        style={{ width: "40%", height: "36px" }}
                                                        placeholder="Input street"
                                                    />
                                                </Form.Item>
                                                <Button type="primary" onClick={handleAddLink}>
                                                    Add
                                                </Button>
                                            </Input.Group>
                                        </Form.Item>
                                    ) : column.dataIndex === "origin" ? (
                                        <Select style={{ width: 120 }} placeholder="Select origin" onChange={(value) => handleChangeOrigin(value)}>
                                            {ORIGIN_OPTIONS?.map((option) => (
                                                <Select.Option value={option.value} key={option.value} color={option.color}>
                                                    {option.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input
                                            onChange={(e) => {
                                                return form.setFieldValue(column.dataIndex, e.target.value);
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            )
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Create Lead
                        </Button>
                    </Form.Item>
                </Form>
                <Modal title="Select a badge" open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel}>
                    <Select style={{ width: "100%" }} placeholder={"Please select a badge"} onChange={handleAddBadge}>
                        {badgesOptions?.map((badge, index) => {
                            return (
                                <Option key={index} value={badge.value}>
                                    {badge.value}
                                </Option>
                            );
                        })}
                    </Select>
                </Modal>
            </Modal>
        </>
    );
};

export default CreateNewLeadForm;
