import { Form, Input, Button, message, Card, Space, Tooltip } from "@pankod/refine-antd";
import { RedoOutlined } from "@ant-design/icons";
import { useState } from "react";
const { Search } = Input;

const HardTemplateForm = ({ newTemplateArgs, setNewTemplateArgs }) => {
    return (
        <Form.Item label="Message Template" name="message">
            <Input.TextArea
                rows={4}
                defaultValue={newTemplateArgs.message}
                onChange={(e) => {
                    setNewTemplateArgs({ ...newTemplateArgs, message: e.target.value });
                }}
            />
        </Form.Item>
    );
};

const LinkTemplateForm = ({ newTemplateArgs, setNewTemplateArgs, handleGenerateClick }) => {
    return (
        <>
            <Form.Item label="General Guideline Message" name="general_guideline_message">
                <Input.TextArea
                    rows={4}
                    defaultValue={newTemplateArgs.general_guideline_message}
                    onChange={(e) => {
                        setNewTemplateArgs({ ...newTemplateArgs, general_guideline_message: e.target.value });
                    }}
                />
            </Form.Item>
            <Form.Item label="Link" name="link">
                <Search
                    enterButton="Search"
                    defaultValue={newTemplateArgs.link}
                    onChange={(e) => {
                        setNewTemplateArgs({ ...newTemplateArgs, link: e.target.value });
                    }}
                />
            </Form.Item>
            <Form.Item label="Content from Link" name="content_from_link">
                <Input.TextArea
                    rows={4}
                    defaultValue={newTemplateArgs.content_from_link}
                    onChange={(e) => {
                        setNewTemplateArgs({ ...newTemplateArgs, content_from_link: e.target.value });
                    }}
                />
            </Form.Item>
            <Button
                onClick={() => {
                    handleGenerateClick();
                }}
                type="primary"
            >
                Test Template
            </Button>
        </>
    );
};

const SoftTemplateForm = ({ newTemplateArgs, setNewTemplateArgs, handleGenerateClick, loadingTestOutput, testOutput }) => {
    const [newField, setNewField] = useState("");

    const handleAddField = () => {
        if (newField === "") {
            message.error("Please enter a field name");
            return;
        }

        setNewTemplateArgs({
            ...newTemplateArgs,
            required_fields: [...(newTemplateArgs.required_fields || []), newField],
        });

        setNewField("");
    };

    const handleDeleteField = (fieldToDelete) => {
        setNewTemplateArgs({
            ...newTemplateArgs,
            required_fields: newTemplateArgs.required_fields.filter((field) => field !== fieldToDelete),
            fields: newTemplateArgs.fields
                ? Object.keys(newTemplateArgs.fields).reduce((acc, field) => {
                      if (field !== fieldToDelete) {
                          acc[field] = newTemplateArgs.fields[field];
                      }
                      return acc;
                  }, {})
                : {},
        });
    };

    return (
        <div style={{ margin: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Form layout="vertical" style={{ width: "100%", maxWidth: "600px" }}>
                <Form.Item label="General Guideline Message" name="general_guideline_message">
                    <Input.TextArea
                        rows={4}
                        defaultValue={newTemplateArgs.message}
                        onChange={(e) => {
                            setNewTemplateArgs({ ...newTemplateArgs, message: e.target.value });
                        }}
                    />
                </Form.Item>

                <Form.Item label="Injected Prompt" name="injected_prompt">
                    <Input
                        defaultValue={newTemplateArgs.injected_prompt}
                        onChange={(e) => {
                            setNewTemplateArgs({ ...newTemplateArgs, injected_prompt: e.target.value });
                        }}
                    />
                </Form.Item>

                <Form.Item label="Content" name="content">
                    <Input.TextArea
                        rows={4}
                        defaultValue={newTemplateArgs?.content || ""}
                        onChange={(e) => {
                            setNewTemplateArgs({ ...newTemplateArgs, content: e.target.value });
                        }}
                    />
                </Form.Item>

                {(newTemplateArgs?.required_fields || []).map((field, index) => (
                    <Form.Item key={index} label={field} name={field} rules={[{ required: true, message: `Please input your ${field}!` }]}>
                        <Input
                            style={{ width: "70%", marginRight: "10px" }}
                            onChange={(e) => {
                                const updatedFields = { ...newTemplateArgs.fields };
                                updatedFields[field] = e.target.value;
                                setNewTemplateArgs({ ...newTemplateArgs, fields: updatedFields });
                            }}
                        />

                        <Button onClick={() => handleDeleteField(field)} type="primary" danger>
                            Remove
                        </Button>
                    </Form.Item>
                ))}

                <Form.Item label="Add Required Field">
                    <Space.Compact style={{ width: "100%" }}>
                        <Input value={newField} onChange={(e) => setNewField(e.target.value)} style={{ width: "70%" }} />
                        <Button onClick={handleAddField} type="primary">
                            Add Field
                        </Button>
                    </Space.Compact>
                </Form.Item>
                <div style={{ margin: "20px 0", textAlign: "center" }}>
                    <Button
                        onClick={() => {
                            if (loadingTestOutput) {
                                message.info("Please wait for the current test to finish");
                            }

                            const requiredFields = newTemplateArgs?.required_fields || [];
                            const fields = newTemplateArgs?.fields || {};
                            const missingFields = requiredFields.filter((field) => !fields[field]);
                            if (missingFields.length > 0) {
                                message.error(`Please fill in the following fields: ${missingFields.join(", ")}`);
                                return;
                            }

                            handleGenerateClick();
                        }}
                        type="primary"
                    >
                        {newTemplateArgs?.message ? (
                            <span>
                                <RedoOutlined />
                                {` Test Again`}
                            </span>
                        ) : (
                            "Test Template"
                        )}
                    </Button>
                </div>

                {loadingTestOutput && <p style={{ textAlign: "center" }}>Generating Test Output...</p>}

                {testOutput && (
                    <Form.Item label="Test Output" name="message">
                        <Card>
                            <p>{testOutput}</p>
                        </Card>
                    </Form.Item>
                )}
            </Form>
        </div>
    );
};

export { HardTemplateForm, LinkTemplateForm, SoftTemplateForm };
