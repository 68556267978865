import React, { useEffect, useState } from "react";

import { Form, Input, Button, message, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { ITemplate } from "interfaces";
import env from "config/site.config";
import { Tag } from "@pankod/refine-antd";
import TestTemplateModal from "../TestTemplateModal";

const { Option } = Select;

const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

const EditTemplatePage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [badgesOptions, setBadgesOptions] = useState<any[]>([]);
    const [selectedBadge, setSelectedBadge] = useState<string | null>(null);
    const [template, setTemplate] = useState<ITemplate | null>(null);
    const [templateType, setTemplateType] = useState<number | null>(null);

    // testing modal
    const [showTestModal, setShowTestModal] = useState(false);
    const [contentValue, setContentValue] = useState<string>("");
    const [chosenBadge, setChosenBadge] = useState<string>("");
    const [chosenBadgeType, setChosenBadgeType] = useState<string | null>(null);
    const [savedWorkflowFunction, setSavedWorkflowFunction] = useState<any>(null);

    useEffect(() => {
        getBadgesOptions().then((res) => {
            setBadgesOptions(res);
        });
    }, []);

    async function getBadgesOptions() {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings`, config);
            if (data?.length > 0) {
                console.log("data[0]?.badges_options", data[0]?.badges_options);

                return data[0]?.badges_options;
            }
        } catch (error) {
            message.error("Error occurred while fetching the badges options");
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/templates/${id}`, config);
                form.setFieldsValue(response.data);
                console.log("response.data", response?.data);
                console.log(form.getFieldValue("badge_value"));

                setTemplate(response?.data);
                console.log("Template type is", response?.data?.type);
                setTemplateType(response?.data?.type);
            } catch (err) {
                message.error("Failed to fetch template!");
            }
        };

        fetchTemplate();
    }, [id, form]);

    const onSubmit = async (values: ITemplate) => {
        try {
            await axios.put(
                `${env.REACT_APP_BACKEND_URL}/api/v1/templates/${id}`,
                {
                    template_name: values.template_name,
                    message: values.message,
                    badge_value: selectedBadge || form.getFieldValue("badge_value"),
                },
                config
            );
            message.success("Template updated successfully!");
            navigate("/templates");
        } catch (err) {
            message.error("Failed to update template!");
        }
    };

    return (
        <div>
            <TestTemplateModal
                visible={showTestModal}
                onClose={() => setShowTestModal(false)}
                content={contentValue}
                badge={chosenBadge}
                type={chosenBadgeType}
                workflowFunction={savedWorkflowFunction}
            />
            <h1>Edit Template</h1>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item label="Template name" name="template_name" rules={[{ required: true, message: "Please input the template_name!" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Badge" name="badge" rules={[{ message: "Please input the badge!" }]}>
                    <div style={{ margin: "10px" }}>
                        Currently belongs to: <Tag color="blue">{form.getFieldValue("badge_value")}</Tag>
                    </div>
                    <Select
                        placeholder={"Select a badge"}
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => {
                            setSelectedBadge(value);
                            form.setFieldsValue({ badge_value: value });
                        }}
                    >
                        {badgesOptions?.map((badge) => (
                            <Option key={badge?.value} value={badge?.value}>
                                {badge?.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="message" name="message" rules={[{ required: true, message: "Please input the content!" }]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                        <Button
                            disabled={!templateType}
                            onClick={() => {
                                setShowTestModal(true);
                                setContentValue(form.getFieldValue("message"));
                                setChosenBadge(form.getFieldValue("badge_value"));
                                setChosenBadgeType(form.getFieldValue("badge"));
                                setSavedWorkflowFunction(template?.workflow_function);
                                // update other states here if needed
                            }}
                        >
                            Test Template
                        </Button>
                        <Button htmlType="button" onClick={() => navigate("/templates")}>
                            Back
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditTemplatePage;
