import React, { useEffect } from "react";
import { useNavigate } from "@pankod/refine-react-router-v6";

import { Table, Button, Space, Input, useEditableTable, Tag, message, Select } from "@pankod/refine-antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { BadgeOption, ITemplate } from "interfaces";
import axios from "axios";
import env from "config/site.config";

const { Option } = Select;

const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

const TemplatesPage = () => {
    const navigate = useNavigate();

    const { tableProps, setId: setEditId } = useEditableTable<ITemplate>({ resource: `templates` });
    const [data, setData] = React.useState<ITemplate[]>([]);
    const [badgesOptions, setBadgesOptions] = React.useState<BadgeOption[]>([]);

    useEffect(() => {
        console.log(tableProps?.dataSource);

        if (tableProps?.dataSource) {
            setData(tableProps.dataSource as ITemplate[]);
        }
    }, [tableProps?.dataSource]);

    const handleAddTemplate = () => {
        navigate("/templates/create");
    };

    useEffect(() => {
        getBadgesOptions().then((res) => {
            setBadgesOptions(res);
        });
    }, []);

    async function getBadgesOptions() {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings`, config);
            if (data?.length > 0) {
                console.log("data[0]?.badges_options", data[0]?.badges_options);
                return data[0]?.badges_options;
            }
        } catch (error) {
            message.error("Error occurred while fetching the badges options");
            console.log(error);
        }
    }

    const getColumnSearchProps = (dataIndex: string) => {
        if (dataIndex === "badge_value") {
            return {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                    <div style={{ padding: 8, display: "flex", flexDirection: "column" }}>
                        <Select
                            showSearch
                            placeholder={`Filter by ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(value) => setSelectedKeys(value ? [value] : [])}
                            style={{ width: 188, marginBottom: 8 }}
                            filterOption={(input: any, option: any) => {
                                return option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        >
                            {badgesOptions.map((badge: any) => (
                                <Option key={badge?.id} value={badge?.value}>
                                    <Tag color={badge?.color}>{badge?.value}</Tag>
                                </Option>
                            ))}
                        </Select>
                        <Space>
                            <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            };
        } else if (dataIndex === "type") {
            const typeOptions = [
                {
                    id: "0",
                    value: "Hard",
                },
                {
                    id: "1",
                    value: "Workflow",
                },
            ];
            return {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                    <div style={{ padding: 8, display: "flex", flexDirection: "column" }}>
                        <Select
                            showSearch
                            placeholder={`Filter by ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(value) => setSelectedKeys(value ? [value] : [])}
                            style={{ width: 188, marginBottom: 8 }}
                            filterOption={(input: any, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {typeOptions.map((type: { id: string; value: string }) => (
                                <Option key={type?.id} value={type?.id}>
                                    {type?.value}
                                </Option>
                            ))}
                        </Select>
                        <Space>
                            <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            };
        } else {
            return {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => confirm()}
                            style={{ width: 188, marginBottom: 8, display: "block" }}
                        />
                        <Space>
                            <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            };
        }
    };

    const columns = [
        {
            title: "Badge Value",
            dataIndex: "badge_value",
            key: "badge_value",
            ...getColumnSearchProps("badge_value"),
            render: (text: any, record: ITemplate) => (
                <Space>
                    <Tag
                        color={
                            // try to find the badge value in the badges options
                            badgesOptions.find((badge: BadgeOption) => badge.value === record?.badge_value)?.color || "blue"
                        }
                    >
                        {record?.badge_value}
                    </Tag>
                </Space>
            ),
        },
        {
            title: "template name",
            dataIndex: "template_name",
            key: "template_name",
            ...getColumnSearchProps("template_name"),
            render: (text: any, record: ITemplate) => (
                <Space>
                    <span>
                        <strong>{record?.template_name}</strong>
                    </span>
                </Space>
            ),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text: any, record: ITemplate) => (
                <Space>
                    {(() => {
                        switch (record.type) {
                            case 0:
                                return <Tag color="blue">Hard</Tag>;
                            case 1:
                                return <Tag color="yellow">Workflow</Tag>;
                            case 2:
                                return <Tag color="red">Soft</Tag>;
                            default:
                                return <Tag color="blue">Hard</Tag>;
                        }
                    })()}
                </Space>
            ),

            ...getColumnSearchProps("type"),
        },
        { title: "Message", dataIndex: "message", key: "message", ...getColumnSearchProps("message") },
        {
            title: "Actions",
            key: "actions",
            render: (text: any, record: ITemplate) => (
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            setEditId(record.id);
                            navigate(`/templates/edit/${record.id}`);
                        }}
                    >
                        Edit
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <h1>Templates</h1>
            <div
                style={{
                    margin: "10px 0",
                }}
            >
                <Button icon={<PlusOutlined />} onClick={handleAddTemplate}>
                    Add Template
                </Button>
            </div>
            <Table<ITemplate> {...tableProps} columns={columns} dataSource={data} pagination={false} />
        </div>
    );
};

export { TemplatesPage };
