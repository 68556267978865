import { Form, Button, Input, notification } from "@pankod/refine-antd";
import { useState } from "react";

import { Modal } from "antd";
import axios from "axios";
import env from "config/site.config";
import { ILead } from "interfaces";

const CreateNewOrganization: React.FC = () => {
    const [form] = Form.useForm();
    const [isMainModalOpen, setMainModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const createOrganization = async (name: string, website: string) => {
        try {
            const res = await axios.post<ILead>(
                `${env.REACT_APP_BACKEND_URL}/api/v1/organizations`,
                { name, website },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            return res.data;
        } catch (error) {
            console.log(error);
            api.error({
                message: "Error",
                description: "Failed to create organization",
            });
        }
    };
    return (
        <>
            {contextHolder}
            <Button
                onClick={() => {
                    setMainModalOpen(true);
                }}
            >
                Create New Organization
            </Button>
            <Modal
                title="Create New Organization"
                open={isMainModalOpen}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            Reset Fields
                        </Button>
                        <Button
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Create
                        </Button>
                    </>
                }
                onCancel={() => {
                    setMainModalOpen(false);
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {
                        console.log("values", values);
                        const { name, website } = values;

                        const res = await createOrganization(name, website);
                        if (res) window.location.reload();
                    }}
                >
                    <Form.Item name="name" label="Name" required>
                        <Input />
                    </Form.Item>
                    <Form.Item name="website" label="Website" required>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateNewOrganization;
