import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { AccountManagerProvider, CampaignDataProvider } from "context/campaignData";
import { FilteringDataProvider } from "context/filteringData";
import { SettingsProvider } from "context/settings";
import { PostHogProvider } from "posthog-js/react";
import env from "config/site.config";

const options = {
    api_host: env.REACT_APP_PUBLIC_POSTHOG_HOST,
    mask_all_text: false,
};

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <PostHogProvider apiKey={env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
            <SettingsProvider>
                <AccountManagerProvider>
                    <CampaignDataProvider>
                        <FilteringDataProvider>
                            <App />
                        </FilteringDataProvider>
                    </CampaignDataProvider>
                </AccountManagerProvider>
            </SettingsProvider>
        </PostHogProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
