import { Form, Button, Input } from "@pankod/refine-antd";
import { useState } from "react";

import { useCampaignData } from "context/campaignData";
import { Select, Modal } from "antd";

const MoveToCampaign: React.FC<{ checkedRows: { [key: string]: boolean }; changeTableValues: (changes: any) => void }> = ({
    checkedRows,
    changeTableValues,
}) => {
    const [form] = Form.useForm();
    const [isMainModalOpen, setMainModalOpen] = useState(false);
    const { userCampaigns } = useCampaignData();
    return (
        <>
            <Button
                onClick={() => {
                    setMainModalOpen(true);
                }}
            >
                Move to Campaign
            </Button>
            <Modal
                title="Move to Campaign"
                open={isMainModalOpen}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            Reset Fields
                        </Button>
                        <Button
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Change
                        </Button>
                    </>
                }
                onCancel={() => {
                    setMainModalOpen(false);
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        console.log("values", values);
                        const clearValues = Object.entries(values).reduce((acc: any, [key, value]) => {
                            if (value !== null) {
                                acc[key] = value;
                            }
                            return acc;
                        }, {});
                        const changes = Object.entries(checkedRows).reduce((acc: any, [id, isChecked]) => {
                            if (isChecked) {
                                acc[id] = clearValues;
                            }
                            return acc;
                        }, {});
                        console.log("changes", changes);
                        changeTableValues(changes);
                        setMainModalOpen(false);
                    }}
                >
                    <div
                        style={{
                            marginBottom: 20,
                        }}
                    >
                        Changes will be applied to <b>{Object.values(checkedRows).filter((v) => v).length}</b> rows.
                    </div>
                    <Form.Item name="campaign_id" label="New Campaign">
                        <Select
                            options={Object.entries(userCampaigns).map(([campaignId, campaignData]: [string, any]) => {
                                return {
                                    label: campaignData.name,
                                    value: campaignId,
                                };
                            })}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MoveToCampaign;
