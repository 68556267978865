import React from "react";

export const FilteringDataContext = React.createContext<
    { filteringData: any; setFilteringData: React.Dispatch<React.SetStateAction<any>> } | undefined
>(undefined);

export const FilteringDataProvider: React.FC<{ children: JSX.Element[] | JSX.Element }> = ({ children }) => {
    const [filteringData, setFilteringData] = React.useState<any>({});
    return <FilteringDataContext.Provider value={{ filteringData, setFilteringData }}>{children}</FilteringDataContext.Provider>;
};

export const useFilteringData = () => {
    const filteringData = React.useContext(FilteringDataContext);
    console.log("filteringData", filteringData);

    if (filteringData === undefined) {
        throw new Error("useFilteringData must be used within a FilteringDataProvider");
    }
    return filteringData;
};
