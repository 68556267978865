import { DeleteButton, SaveButton, notification, Space, Checkbox, Button, message, Switch, Tooltip } from "@pankod/refine-antd";
import { Form, Input, Select } from "antd";
import { IProfile, IUser } from "interfaces";
import { FC, useEffect, useState } from "react";
import axios from "axios";
import env from "config/site.config";
// import Select from "react-select";

const EditableProfileRow: FC<{ profile?: IProfile; organizationId: string; users: IUser[] }> = ({ profile, organizationId, users }) => {
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);
    const [webhookUrl, setWebhookUrl] = useState<string | undefined>(profile?.rb2b_webhook_url);
    const [websiteVisitorActive, setWebsiteVisitorActive] = useState(profile?.website_visitor_active);
    const [api, contextHolder] = notification.useNotification();
    const operatingUser = users.find((user) => user.user_id === profile?.operating_user_id);

    useEffect(() => {
        if (profile?.rb2b_webhook_url) {
            setWebhookUrl(profile.rb2b_webhook_url);
        }
    }, [profile]);

    const createProfile = async (
        first_name: string,
        last_name: string,
        linkedin_automation_id: string | null = null,
        operating_user_id: string | null = null,
        manual_sales_navigator_url: string | null = null,
        override_sales_navigator_url: boolean
    ) => {
        try {
            const res = await axios.post<IProfile>(
                `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}/profile`,
                { first_name, last_name, linkedin_automation_id, operating_user_id, manual_sales_navigator_url, override_sales_navigator_url },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            api.success({
                message: "Success",
                description: "Profile created",
            });
            window.location.reload();

            return res.data;
        } catch (error: any) {
            form.resetFields();
            setDisabled(true);
            api.error({
                message: "Error",
                description: error.response.data.message,
            });
        }
    };

    const updateProfile = async (
        first_name: string,
        last_name: string,
        profile_id: string,
        linkedin_automation_id: string | null = null,
        operating_user_id: string | null = null,
        manual_sales_navigator_url: string | null = null,
        override_sales_navigator_url: boolean,
        active: boolean,
        visible: boolean
    ) => {
        try {
            const res = await axios.put<IProfile>(
                `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}/profile/${profile_id}`,
                {
                    first_name,
                    last_name,
                    linkedin_automation_id,
                    operating_user_id,
                    manual_sales_navigator_url,
                    override_sales_navigator_url,
                    active,
                    visible,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            api.success({
                message: "Success",
                description: "Profile updated",
            });

            return res.data;
        } catch (error: any) {
            form.resetFields();
            setDisabled(true);
            api.error({
                message: "Error",
                description: error.response.data.message,
            });
        }
    };

    const toggleActiveVisibleForProfile = async (profile_id: string, active: boolean, visible: boolean) => {
        try {
            const res = await axios.put<IProfile>(
                `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}/profile/${profile_id}/toggle-active-visible`,
                {
                    active,
                    visible,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            api.success({
                message: "Success",
                description: "Profile updated",
            });

            return res.data;
        } catch (error: any) {
            form.resetFields();
            setDisabled(true);
            api.error({
                message: "Error",
                description: error.response.data.message,
            });
        }
    };

    console.log("form", form.getFieldValue("visible"));
    console.log("form", form.getFieldValue("active"));

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    ...profile,
                    operating_user_id: operatingUser ? profile?.operating_user_id : undefined,
                    override_sales_navigator_url: profile?.override_sales_navigator_url ?? false,
                    manual_sales_navigator_url: profile?.manual_sales_navigator_url,
                }}
                onValuesChange={(changedValues, allValues) => {
                    if (profile) {
                        if (
                            allValues.first_name !== profile?.first_name ||
                            allValues.last_name !== profile?.last_name ||
                            allValues.linkedin_automation_id !== profile?.linkedin_automation_id ||
                            allValues.operating_user_id !== profile?.operating_user_id ||
                            allValues.manual_sales_navigator_url !== profile?.manual_sales_navigator_url ||
                            allValues.override_sales_navigator_url !== profile?.override_sales_navigator_url
                        ) {
                            setDisabled(false);
                        } else {
                            setDisabled(true);
                        }
                    } else {
                        console.log("allValues", allValues);
                        if (
                            allValues.first_name &&
                            allValues.last_name &&
                            (!users.length || allValues.operating_user_id) &&
                            !(allValues.override_sales_navigator_url && !allValues.manual_sales_navigator_url)
                        ) {
                            setDisabled(false);
                        } else {
                            setDisabled(true);
                        }
                    }
                }}
                onFinish={async (values) => {
                    if (
                        !values.first_name ||
                        !values.last_name ||
                        (!!users.length && !values.operating_user_id) ||
                        (values.override_sales_navigator_url && !values.manual_sales_navigator_url)
                    ) {
                        api.error({
                            message: "Error",
                            description: `required fields are missing`,
                        });
                        return;
                    }

                    console.log("values: ", values);

                    if (profile) {
                        await updateProfile(
                            values.first_name,
                            values.last_name,
                            profile.profile_id,
                            values.linkedin_automation_id,
                            values.operating_user_id,
                            values.manual_sales_navigator_url,
                            values.override_sales_navigator_url,
                            values.active,
                            values.visible
                        );
                    } else {
                        await createProfile(
                            values.first_name,
                            values.last_name,
                            values.linkedin_automation_id,
                            values.operating_user_id,
                            values.manual_sales_navigator_url,
                            values.override_sales_navigator_url
                        );
                    }
                }}
                layout="inline"
            >
                <Form.Item name="first_name" label="First Name" required>
                    <Input />
                </Form.Item>
                <Form.Item name="last_name" label="Last Name" required>
                    <Input />
                </Form.Item>
                <Form.Item name="linkedin_automation_id" label="LinkedIn Automation ID">
                    <Input />
                </Form.Item>
                <Form.Item name="operating_user_id" label="Operating User" style={{ minWidth: "200" }} required={!!users.length}>
                    <Select
                        placeholder="Select operating user"
                        options={users.map((user) => ({ value: user.user_id, label: user.name }))}
                        optionFilterProp="children"
                        showSearch
                        style={{ minWidth: "200px" }}
                        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                    />
                </Form.Item>
                <Form.Item
                    name="manual_sales_navigator_url"
                    label="Manual Sales Navigator URL"
                    required={form.getFieldValue("override_sales_navigator_url")}
                >
                    <Input />
                </Form.Item>
                <Tooltip title="If checked, the profile will be visible in the frontend">
                    <Form.Item name="visible" label="Visible" valuePropName="checked">
                        <Switch
                            onChange={(value) => {
                                toggleActiveVisibleForProfile(profile?.profile_id as string, form.getFieldValue("active"), value);
                            }}
                        />
                    </Form.Item>
                </Tooltip>
                <Tooltip title="Active profile means a profile that has an active subscription in Samplead">
                    <Form.Item name="active" label="Active" valuePropName="checked">
                        <Switch
                            onChange={(value) => {
                                toggleActiveVisibleForProfile(profile?.profile_id as string, value, form.getFieldValue("visible"));
                            }}
                        />
                    </Form.Item>
                </Tooltip>
                <Form.Item name="override_sales_navigator_url" label="Override Sales Navigator URL?" valuePropName="checked">
                    <Checkbox onClick={() => console.log("form override_sales_nav_url", form.getFieldValue("override_sales_navigator_url"))} />
                </Form.Item>
                <Space
                    style={{
                        marginRight: "1rem",
                    }}
                >
                    <SaveButton
                        onClick={() => {
                            form.submit();
                        }}
                        disabled={disabled}
                    >
                        {profile ? "Save" : "Create"}
                    </SaveButton>
                    {profile && !profile.linkedin_automation_id && (
                        <DeleteButton
                            resourceNameOrRouteName={`api/v1/organizations/${organizationId}/profile`}
                            recordItemId={profile.profile_id}
                            onSuccess={(value) => {
                                window.location.reload();
                            }}
                        >
                            Delete
                        </DeleteButton>
                    )}
                </Space>

                {contextHolder}
            </Form>
            {profile && (
                <>
                    {webhookUrl ? (
                        <Space>
                            <Input
                                value={"Copy webhook to RB2B"}
                                readOnly
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(webhookUrl);
                                    api.success({
                                        message: "Success",
                                        description: "Copied to clipboard",
                                    });
                                }}
                            />
                            <Switch
                                checkedChildren="Website Visitor trigger active"
                                unCheckedChildren="Website Visitor trigger inactive"
                                checked={websiteVisitorActive}
                                onChange={async (checked) => {
                                    try {
                                        await axios.post<IProfile>(
                                            `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}/profile/${profile.profile_id}/toggle-rb2b-trigger`,
                                            {
                                                enabled: checked,
                                            },
                                            {
                                                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                                            }
                                        );

                                        setWebsiteVisitorActive(checked);
                                        api.success({
                                            message: "Success",
                                            description: "Website Visitor Campaign toggled",
                                        });
                                    } catch (error: any) {
                                        api.error({
                                            message: "Error",
                                            description: error.response.data.message,
                                        });
                                    }
                                }}
                                // center div
                                style={{}}
                            />
                        </Space>
                    ) : (
                        <Button
                            onClick={async () => {
                                try {
                                    const res = await axios.post<IProfile>(
                                        `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}/profile/${profile.profile_id}/create-rb2b-campaign`,
                                        {},
                                        {
                                            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                                        }
                                    );

                                    setWebhookUrl(res.data.rb2b_webhook_url);
                                    api.success({
                                        message: "Success",
                                        description: "Webhook URL generated",
                                    });
                                } catch (error: any) {
                                    api.error({
                                        message: "Error",
                                        description: error.response.data.message,
                                    });
                                }
                            }}
                        >
                            Generate Website Visitor Campaign
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

export default EditableProfileRow;
