import axios from "axios";
import env from "config/site.config";

export const getUserSettings = async (campaignId: string) => {
    try {
        const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/user_settings/${campaignId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        if (data?.length > 0) {
            const companyBlacklist = new Set();
            Object.values(data[0].company_blacklist).forEach((list: any) => {
                list.forEach((company: string) => companyBlacklist.add(company?.replace(/\s/g, "")?.toLowerCase()));
            });

            data[0].company_blacklist = companyBlacklist;

            // save to local storage
            localStorage.setItem(`userSettings${campaignId}`, JSON.stringify(data[0]));
            return data[0];
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const isConnected = async (campaignId: string, url: string) => {
    try {
        const { data } = await axios.post(
            `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/is-connected`,
            { campaignId, url },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
        );
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
