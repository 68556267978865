import { Form, Button, Input } from "@pankod/refine-antd";
import { useState } from "react";

import { STATUS_OPTIONS, ORIGIN_OPTIONS } from "utils/constants";
import { Select, Modal } from "antd";
import { useCampaignData } from "context/campaignData";

const ChangeBulk: React.FC<{ checkedRows: { [key: string]: boolean }; changeTableValues: (changes: any) => void; adminBadges: any[] }> = ({
    checkedRows,
    changeTableValues,
    adminBadges,
}) => {
    const [form] = Form.useForm();
    const [isMainModalOpen, setMainModalOpen] = useState(false);
    const { campaignId, campaignData } = useCampaignData();

    return (
        <>
            <Button
                onClick={() => {
                    setMainModalOpen(true);
                }}
            >
                Change Bulk
            </Button>
            <Modal
                title="Change Bulk"
                open={isMainModalOpen}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            Reset Fields
                        </Button>
                        <Button
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Change
                        </Button>
                    </>
                }
                onCancel={() => {
                    setMainModalOpen(false);
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        console.log("values", values);
                        const clearValues = Object.entries(values).reduce((acc: any, [key, value]) => {
                            if (value !== null) {
                                acc[key] = value;
                            }
                            return acc;
                        }, {});
                        const changes = Object.entries(checkedRows).reduce((acc: any, [id, isChecked]) => {
                            if (isChecked) {
                                acc[id] = clearValues;
                            }
                            return acc;
                        }, {});
                        changeTableValues(changes);
                        console.log("changes", changes);
                        // setMainModalOpen(false);
                    }}
                >
                    <div>
                        Changes will be applied to <b>{Object.values(checkedRows).filter((v) => v).length}</b> rows.
                    </div>
                    <div
                        style={{
                            marginBottom: 10,
                        }}
                    >
                        <b>Leave empty to not change</b>
                    </div>
                    {campaignData?.name !== "Automated Trigger Bucket" && (
                        <Form.Item name="status" label="Status">
                            <Select options={STATUS_OPTIONS} />
                        </Form.Item>
                    )}
                    <Form.Item name="origin" label="Origin">
                        <Select options={ORIGIN_OPTIONS} />
                    </Form.Item>
                    <Form.Item name="admin_note" label="Admin Note">
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ChangeBulk;
