import { Card, Col, Row, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import env from "config/site.config";

const MessageTemplates = ({ templates, admin_id, handleDelete }: any) => {
    return (
        <div>
            <Row gutter={[16, 16]}>
                {templates?.map((template: any) => (
                    <Col key={template.id} xs={24} sm={12} md={8} lg={6}>
                        <Card
                            title={template.name}
                            actions={[
                                <Space key="actions">
                                    <DeleteOutlined onClick={() => handleDelete(template.id)} />
                                </Space>,
                            ]}
                        >
                            <strong>Template:</strong>

                            <p>{template.message}</p>

                            <div>
                                <strong>Required Fields:</strong>
                                {template?.required_fields && template?.required_fields?.length !== 0 ? (
                                    template?.required_fields?.map((variable: any) => <p key={variable}>{variable}</p>)
                                ) : (
                                    <p>None</p>
                                )}
                            </div>
                            {template?.injected_prompt && (
                                <div>
                                    <strong>Injected Prompt:</strong>
                                    <p>{template.injected_prompt}</p>
                                </div>
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default MessageTemplates;
