import React, { useState, useEffect, useMemo } from "react";
import { Form, Input, Modal, useTable } from "@pankod/refine-antd";
import { Table, Tag, Button, message, Space, Spin, Tooltip } from "antd";
import type { ColumnType } from "antd/es/table";
import { PlayCircleOutlined, PauseCircleOutlined, SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import env from "config/site.config";
import { useCampaignData } from "context/campaignData";
import { CrudFilters, GetListResponse } from "@pankod/refine-core";

interface Post {
    attachments: Array<{
        image: string;
        text: string;
        type: string;
    }>;
    author_name: string;
    author_type: "PERSON" | "COMPANY";
    publisher_fetched_count: number;
    publisher_pushed_count: number;
    author_url: string;
    author_urn: string;
    comments_count: number;
    comments_fetched_count: number;
    comments_last_cursor: string | null;
    comments_pushed_count: number;
    content: string;
    created_at: string;
    expiration_date: string;
    post_id: string;
    post_url: string;
    post_urn: string;
    profile_id: string;
    published_at: string;
    reactions_count: number;
    reactions_fetched_count: number;
    reactions_last_cursor: string | null;
    reactions_pushed_count: number;
    reposts_count: number;
    reposts_fetched_count: number;
    reposts_last_cursor: string | null;
    reposts_pushed_count: number;
    source: "USER" | "ADMIN" | "AUTO";
    status: "ACTIVE" | "CANCELLED" | "OUTDATED";
    updated_at: string;
}

const PostTable: React.FC = () => {
    const { campaignData } = useCampaignData();
    const [posts, setPosts] = useState<Post[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [loadingToggle, setLoadingToggle] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [linkedinPostTriggerOff, setLinkedinPostTriggerOff] = useState(false);

    const { tableProps, searchFormProps, sorter, setSorter, filters, setFilters, current, setCurrent, pageSize, setPageSize, tableQueryResult } =
        useTable<Post>({
            resource: `triggers_automation/${campaignData.userId}`,
            permanentFilter: [{ field: "status", operator: "eq", value: "ACTIVE" }],
            onSearch: (params): CrudFilters => {
                const filters: CrudFilters = [];
                const { q } = params as { q?: string };
                if (q) {
                    filters.push({
                        field: "q",
                        operator: "eq",
                        value: q,
                    });
                }
                return filters;
            },
        });

    useEffect(() => {
        console.log("tableQueryResult", tableQueryResult);
        // @ts-expect-error
        if (tableQueryResult?.data?.data?.posts) {
            console.log("tableQueryResult", tableQueryResult);
            // @ts-expect-error
            const linkedInPostTrigger = tableQueryResult?.data?.data?.posts;
            console.log("linkedInPostTrigger", linkedInPostTrigger);
            // @ts-expect-error
            if (!tableQueryResult?.data?.data?.active) {
                setLinkedinPostTriggerOff(true);
                setPosts(linkedInPostTrigger);
                return;
            }
            setPosts(linkedInPostTrigger);
        } else {
            setPosts([]);
        }
    }, [tableQueryResult.data]);

    const handleSearch = (value: string) => {
        setSearchText(value);
        setCurrent(1); // Reset to first page when searching
        setFilters([{ field: "q", operator: "eq", value }]);
    };

    const filteredAndSortedPosts = useMemo(() => {
        console.log("posts", posts);
        return posts
            .filter((post) => Object.values(post).some((val) => val?.toString().toLowerCase().includes(searchText.toLowerCase())))
            .sort((a, b) => {
                if (a.status !== b.status) {
                    return a.status === "ACTIVE" ? -1 : 1;
                }
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
    }, [posts, searchText]);

    const renderPushedTotal = (pushed: number, total: number) => (
        <Tooltip title={`${pushed} pushed out of ${total} total`}>
            <span>{pushed}</span> <span>{"/ "}</span>
            <span style={{ fontWeight: "bold" }}>{total}</span>
        </Tooltip>
    );

    const columns: ColumnType<Post>[] = [
        {
            title: "Post By",
            dataIndex: "author_name",
            key: "author",
            render: (author_name: string, record: Post) => (
                <a href={record.post_url} target="_blank" rel="noopener noreferrer">
                    {author_name}
                </a>
            ),
        },
        {
            title: "Publisher",
            key: "publisher",
            render: (record: Post) => renderPushedTotal(record.publisher_pushed_count, record.publisher_fetched_count),
        },
        {
            title: "Reposts",
            key: "reposts",
            render: (record: Post) => renderPushedTotal(record.reposts_pushed_count, record.reposts_count),
        },
        {
            title: "Comments",
            key: "comments",
            render: (record: Post) => renderPushedTotal(record.comments_pushed_count, record.comments_count),
        },
        {
            title: "Reactions",
            key: "reactions",
            render: (record: Post) => renderPushedTotal(record.reactions_pushed_count, record.reactions_count),
        },
        {
            title: "Total Yield",
            key: "yield",
            render: (record: Post) => {
                const totalPushed =
                    record.reposts_pushed_count + record.comments_pushed_count + record.reactions_pushed_count + record.publisher_pushed_count;
                const totalCount = record.reposts_count + record.comments_count + record.reactions_count + record.publisher_fetched_count;
                return renderPushedTotal(totalPushed, totalCount);
            },
        },
        {
            title: "Source",
            dataIndex: "source",
            key: "source",
            render: (source: Post["source"]) => (
                <Tag color={source === "USER" ? "green" : source === "ADMIN" ? "blue" : "volcano"}>{source?.toUpperCase() || ""}</Tag>
            ),
        },
        {
            title: "Added Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (date: string) => new Date(date).toLocaleDateString(),
        },
        {
            title: "Action",
            key: "action",
            render: (_: any, record: Post) => (
                <Button
                    onClick={() => handlePlayPause(record)}
                    icon={loadingToggle ? <LoadingOutlined /> : record.status === "ACTIVE" ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                    type="text"
                    disabled={loadingToggle || linkedinPostTriggerOff}
                >
                    {loadingToggle ? "Updating" : record.status === "ACTIVE" ? "Pause" : "Play"}
                </Button>
            ),
        },
    ];

    const handlePlayPause = async (record: Post) => {
        const newStatus = record.status === "ACTIVE" ? "CANCELLED" : "ACTIVE";
        try {
            setLoadingToggle(true);
            const response = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/triggers_automation/toggle`,
                {
                    post_urn: record.post_urn,
                    profileId: campaignData.userId,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            if (response.status === 200) {
                setPosts(posts.map((post) => (post.post_urn === record.post_urn ? { ...post, status: newStatus } : post)));
                message.success(`Post ${newStatus === "ACTIVE" ? "activated" : "deactivated"}`);
            } else {
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            console.error("Error details:", error);
            if (axios.isAxiosError(error)) {
                console.error("Axios error:", error.response?.data, error.response?.status, error.response?.headers);
            }
            message.error("Failed to update post status. Please check the console for more details.");
        } finally {
            setLoadingToggle(false);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleAddPost = async (values: { postUrl: string }) => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/triggers_automation/add-post`,
                {
                    profileId: campaignData.userId,
                    postUrl: values.postUrl,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            if (response.status === 200) {
                message.success(response.data.message);
                setIsModalVisible(false);
                form.resetFields();
                if (tableQueryResult.refetch) {
                    tableQueryResult.refetch();
                }
            }
        } catch (error) {
            message.error("Failed to add post. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const safeData = Array.isArray((tableQueryResult.data as any)?.data?.posts) ? (tableQueryResult.data as any).data.posts : [];

    return (
        <>
            <h3>LinkedIn Posts</h3>
            {linkedinPostTriggerOff && (
                <p style={{ color: "grey" }}>
                    LinkedIn Post Trigger is off for this profile. You can't add new posts or pause/unpause existing posts.
                </p>
            )}
            <Space style={{ marginBottom: 16 }}>
                <Input placeholder="Search posts" onChange={(e) => handleSearch(e.target.value)} style={{ width: 200 }} prefix={<SearchOutlined />} />
                <Button onClick={showModal} icon={<div></div>} disabled={tableQueryResult.isLoading || linkedinPostTriggerOff}>
                    Add Post
                </Button>
            </Space>
            <Spin spinning={tableQueryResult.isLoading || loading}>
                <Table<Post>
                    {...tableProps}
                    columns={columns}
                    dataSource={safeData}
                    rowKey="post_urn"
                    pagination={{
                        ...tableProps.pagination,
                        total: (tableQueryResult.data as GetListResponse<Post>)?.total,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    onChange={(pagination) => {
                        setCurrent(pagination.current || 1);
                        setPageSize(pagination.pageSize || 10);
                    }}
                />
            </Spin>
            <Modal title="Add New Post" open={isModalVisible} onCancel={handleCancel} footer={null}>
                <Form form={form} onFinish={handleAddPost}>
                    <Form.Item name="postUrl" rules={[{ required: true, message: "Please input the post content!" }]}>
                        <Input placeholder="Enter post URL" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {"Add Post"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PostTable;
