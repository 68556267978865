import React from "react";

export const CampaignDataContext = React.createContext<
    | {
          campaignId: string;
          setCampaignId: (campaignId: string) => void;
          campaignData: any;
          setCampaignData: (campaignData: any) => void;
          userCampaigns: any;
          setUserCampaigns: (userCampaigns: any) => void;
      }
    | undefined
>(undefined);

export const AccountManagerContext = React.createContext<
    | {
          accountManager: string;
          setAccountManager: (accountManager: string) => void;
      }
    | undefined
>(undefined);

export const CampaignDataProvider: React.FC<{ children: JSX.Element[] | JSX.Element }> = ({ children }) => {
    // get campaignId from localStorage
    const getCampaignId = () => {
        const campaignId = localStorage.getItem("campaignId");
        return campaignId ? campaignId : "";
    };
    const setCampaignId = (campaignId: string) => {
        localStorage.setItem("campaignId", campaignId);
        setCampaignIdState(campaignId);
    };
    const getCampaignData = () => {
        const campaignData = localStorage.getItem("campaignData");
        return campaignData ? JSON.parse(campaignData) : {};
    };
    const setCampaignData = (campaignData: any) => {
        localStorage.setItem("campaignData", JSON.stringify(campaignData));
        setCampaignDataState(campaignData);
    };
    const getUserCampaigns = () => {
        const userCampaigns = localStorage.getItem("userCampaigns");
        return userCampaigns ? JSON.parse(userCampaigns) : {};
    };
    const setUserCampaigns = (userCampaigns: any) => {
        localStorage.setItem("userCampaigns", JSON.stringify(userCampaigns));
        console.log("userCampaigns", userCampaigns);
        setUserCampaignsState(userCampaigns);
    };
    const [campaignId, setCampaignIdState] = React.useState<string>(getCampaignId());
    const [campaignData, setCampaignDataState] = React.useState<any>(getCampaignData());
    const [userCampaigns, setUserCampaignsState] = React.useState<any>(getUserCampaigns());

    return (
        <CampaignDataContext.Provider value={{ campaignId, setCampaignId, campaignData, setCampaignData, userCampaigns, setUserCampaigns }}>
            {children}
        </CampaignDataContext.Provider>
    );
};

export const AccountManagerProvider: React.FC<{ children: JSX.Element[] | JSX.Element }> = ({ children }) => {
    // get accountManager from localStorage
    const getAccountManager = () => {
        const accountManager = localStorage.getItem("accountManager");
        return accountManager ? accountManager : "";
    };
    const setAccountManager = (accountManager: string) => {
        localStorage.setItem("accountManager", accountManager);
        setAccountManagerState(accountManager);
    };
    const [accountManager, setAccountManagerState] = React.useState<string>(getAccountManager());
    return <AccountManagerContext.Provider value={{ accountManager, setAccountManager }}>{children}</AccountManagerContext.Provider>;
};

export const useCampaignData = () => {
    const campaignData = React.useContext(CampaignDataContext);
    if (campaignData === undefined) {
        throw new Error("useCampaignData must be used within a CampaignDataProvider");
    }
    return campaignData;
};

export const useAccountManager = () => {
    const accountManager = React.useContext(AccountManagerContext);
    if (accountManager === undefined) {
        throw new Error("useAccountManager must be used within a AccountManagerProvider");
    }
    return accountManager;
};
