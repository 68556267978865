import React, { useEffect, useRef } from "react";
import { Layout, Space, Typography } from "antd";
import { useLogin } from "@pankod/refine-core";
import { Card } from "@pankod/refine-antd";
interface CredentialResponse {
    credential: any;
}

interface CustomWindow extends Window {
    google?: any;
}

const clientId = "345800087528-hnf3j3kk8n0er6c7p97gvci9u6lhaqbl.apps.googleusercontent.com";
const loginUri = "https://admin-tool.samplead.co/";

const GoogleButton: React.FC = () => {
    const { mutate: mutateLogin } = useLogin();
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            const customWindow = window as CustomWindow;

            if (typeof customWindow === "undefined" || !customWindow.google || !divRef.current) {
                return;
            }

            customWindow.google.accounts.id.initialize({
                client_id: clientId,
                ux_mode: "popup",
                callback: async (res: any) => {
                    if (res.credential) {
                        const credential: CredentialResponse = {
                            credential: res,
                        };
                        mutateLogin(credential);
                    }
                },
            });
            customWindow.google.accounts.id.renderButton(divRef.current, {
                theme: "outline",
                size: "large",
                text: "signin_with",
                type: "standard",
                shape: "pill",
                logo_alignment: "left",
            });
        };
        document.head.appendChild(script);
    }, [clientId]);

    return <div id="g_id_signin" ref={divRef} data-login_uri={loginUri} data-context="use" />;
};

export const Login: React.FC = () => {
    return (
        <Layout
            style={{
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Card>
                <Space direction="vertical" align="center" size="large">
                    <div
                        style={{
                            margin: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <img src="https://samplead-webapp-bucket.s3.amazonaws.com/purple-logo.png" alt="Samplead" style={{ width: "170px" }} />
                        <div
                            style={{
                                fontSize: "16px",
                                fontWeight: "800",
                                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                                padding: "10px 0px",
                                textTransform: "uppercase",
                                letterSpacing: "2px",
                                background: "linear-gradient(90deg,#5510eb, #4a00e7 )",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Admin Tool
                        </div>
                    </div>
                    <GoogleButton />
                    <Typography.Text type="secondary">Powered by Google</Typography.Text>
                </Space>
            </Card>
        </Layout>
    );
};
