import {
    List,
    Table,
    Form,
    Space,
    Button,
    SaveButton,
    EditButton,
    Input,
    TextField,
    useEditableTable,
    Tag,
    Select,
    Card,
    UploadProps,
    Statistic,
    Row,
    Col,
    notification,
} from "@pankod/refine-antd";
import { useCampaignData } from "context/campaignData";
import { useEffect, useState } from "react";
import { IResourceComponentsProps, useQuery } from "@pankod/refine-core";
import { ICampaignText, IUserSettings, UserSetting } from "interfaces";
import { USER_SETTINGS_COLUMNS, DENIED_REASONS_MAP, DenyReasons } from "utils/constants";
import { TextareaAutosize } from "@pankod/refine-mui";
import axios from "axios";
import env from "config/site.config";
import { PlusOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@mui/icons-material";
import Papa from "papaparse";
import { ColumnType } from "antd/lib/table/interface";
import { ArrowDownOutlined, ArrowUpOutlined, LikeOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import { message, Popconfirm, Upload } from "antd";

import "./UserSettings.css";
import PerformanceCard from "components/PerformanceCard";
import { chunk } from "lodash";

const { Dragger } = Upload;

const UserSettings: React.FC<IResourceComponentsProps> = () => {
    const { campaignId } = useCampaignData();
    const [performanceStatistics, setPerformanceStatistics] = useState<any>(null);
    const [deniedReasons, setDeniedReasons] = useState<any>(null);
    const [userId, setUserId] = useState<any>(null);
    const [confirmLockText, setConfirmLockText] = useState<string>("");
    const [promptUserWithLock, setPromptUserWithLock] = useState<boolean>(false);
    const {
        tableProps,
        formProps,
        isEditing,
        setId: setEditId,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
    } = useEditableTable<IUserSettings>({ resource: `user_settings/${campaignId}` });

    const ARRAY_TYPE_COLUMNS = [
        "competitors",
        "geographical_targets",
        "hashtags",
        "keywords",
        "linkedin_profiles",
        "perfect_customers",
        "seniority_level",
        "target_company_headcount",
        "target_industries",
        "target_role_function",
        "target_titles",
        "tracking_targets",
        "excluded_cities",
        "excluded_titles",
        "influencers",
        "linkedin_groups",
        "specific_territories",
        "denied_industries",
        "email_cc_list",
    ];

    const USER_CARD_COLUMNS = ["user_id", "full_name", "company_name", "work_email", "company_website"];

    const CONFIRMATION_STRING: string = "confirm";

    useEffect(() => {
        if (userId || !tableProps?.dataSource?.[0]?.user_id) {
            return;
        }
        const userIdFromTable = tableProps?.dataSource?.[0]?.user_id;

        console.log("tableProps", tableProps);

        setUserId(userIdFromTable);
    }, [tableProps]);

    useEffect(() => {
        if (userId) {
            getStatistics(userId);
            getDeniedReasons(userId);
        }
    }, [userId]);

    useEffect(() => {
        console.log("deniedReasons", deniedReasons);
    }, [deniedReasons]);

    const getStatistics = async (userId: any) => {
        if (performanceStatistics) {
            return;
        }
        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/api/v1/user_settings/statistics/${userId}`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            console.log("data", data);
            setPerformanceStatistics(data);
        } catch (error) {
            console.log(error);
        }
    };

    async function getDeniedReasons(userId: any) {
        if (deniedReasons) {
            return;
        }
        axios
            .get(`${env.REACT_APP_BACKEND_URL}/api/v1/user_settings/denied_reasons/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                console.log(res);
                setDeniedReasons(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const CHUNK_SIZE = 1000;

    const dropperProps: UploadProps = {
        beforeUpload: (file) => {
            console.log("Parsing file", file);

            if (!file.type.includes("csv")) {
                console.log(file.type);
                message.error("File type not supported");
                return false;
            }

            Papa.parse(file, {
                header: true,
                delimiter: ",",
                skipEmptyLines: true,
                complete: async function (results) {
                    const jsonData: any = results.data;
                    console.log("csv json data", jsonData);
                    const columnNames = Object.keys(jsonData[0]).map((name) => name.toLowerCase().replace(/\s/g, ""));
                    console.log(columnNames);

                    if (columnNames.includes("blacklist") || columnNames.includes("black list")) {
                        const blacklistValues = jsonData.map((row: any) => row?.blacklist || row?.["black list"]);
                        console.log("blacklistValues", blacklistValues);

                        // Split the array into chunks of 1000
                        const chunks = chunk(blacklistValues, CHUNK_SIZE);
                        let successCount = 0;
                        let failureCount = 0;

                        try {
                            // Process each chunk sequentially
                            for (let i = 0; i < chunks.length; i++) {
                                const currentChunk = chunks[i];
                                try {
                                    await axios.post(
                                        `${env.REACT_APP_BACKEND_URL}/api/v1/user_settings/blacklist/${campaignId}`,
                                        {
                                            blacklist: currentChunk,
                                        },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                                            },
                                        }
                                    );
                                    successCount++;
                                    message.success(`Processed chunk ${i + 1} of ${chunks.length} successfully`);
                                } catch (err) {
                                    console.error(`Error processing chunk ${i + 1}:`, err);
                                    failureCount++;
                                    message.error(`Failed to process chunk ${i + 1} of ${chunks.length}`);
                                }
                            }

                            // Final status message
                            if (failureCount === 0) {
                                message.success("All blacklist values updated successfully");
                            } else {
                                message.warning(`Completed with ${successCount} successful and ${failureCount} failed chunks`);
                            }
                        } catch (err) {
                            console.error("Error during chunk processing:", err);
                            message.error("Failed to process blacklist updates");
                        }
                    } else {
                        message.error("Blacklist column not found");
                    }
                },
            });
            return false;
        },
        multiple: false,
    };

    const clearBlacklist = () => {
        axios
            .post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/user_settings/blacklist/${campaignId}`,
                {
                    blacklist: [],
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            )
            .then((res) => {
                console.log(res);

                message.success("Blacklist cleared");
            })
            .catch((err) => {
                console.log(err);
                message.error("Blacklist clear failed");
            });
    };

    const saveIcpDescriptionChanges = () => {
        saveButtonProps?.onClick();
        setPromptUserWithLock(false);
        setConfirmLockText("");
    };

    return (
        <List>
            <Form
                {...formProps}
                style={{
                    display: "flex",
                }}
            >
                <Table
                    {...tableProps}
                    rowKey="id"
                    className="fixed-size-table"
                    onRow={(record) => ({
                        // eslint-disable-next-line
                        onClick: (event: any) => {
                            if (event.target.nodeName === "TD") {
                                setEditId && setEditId(record.id);
                            }
                        },
                    })}
                >
                    {USER_SETTINGS_COLUMNS?.map((column) => {
                        switch (column.dataIndex) {
                            case "id":
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            return value;
                                        }}
                                    />
                                );
                            case "company_blacklist":
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            const isCompanyBlacklistEmpty = value?.manual_upload?.length > 0;

                                            if (!record.company_blacklist || !isCompanyBlacklistEmpty) {
                                                return (
                                                    <Dragger
                                                        style={{
                                                            maxWidth: "500px",
                                                        }}
                                                        {...dropperProps}
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Add Blacklist CSV</p>
                                                    </Dragger>
                                                );
                                            }
                                            if (isEditing(record.id)) {
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <Select
                                                            mode="tags"
                                                            style={{ width: "100%" }}
                                                            placeholder="Tags Mode"
                                                            defaultValue={value?.manual_upload}
                                                        />
                                                    </Form.Item>
                                                );
                                            } else {
                                                console.log("value", value);

                                                return (
                                                    <Space direction="vertical">
                                                        <div
                                                            style={{
                                                                // clutter tags
                                                                display: "flex",
                                                                flexWrap: "wrap",
                                                                width: "100%",
                                                                justifyContent: "space-between",
                                                                gap: "5px",
                                                            }}
                                                        >
                                                            {value?.manual_upload?.map(
                                                                (item: string, i: number) =>
                                                                    i < 4 && (
                                                                        <Tag color="black" key={item}>
                                                                            {item}
                                                                        </Tag>
                                                                    )
                                                            )}
                                                            {value?.manual_upload?.length > 4 && (
                                                                <Tag color="black" key="more">
                                                                    +{value?.manual_upload?.length - 4} more
                                                                </Tag>
                                                            )}
                                                        </div>

                                                        <Popconfirm
                                                            title="Are you sure you want to clear the blacklist?"
                                                            onConfirm={() => {
                                                                clearBlacklist();
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button>Clear Blacklist</Button>
                                                        </Popconfirm>
                                                    </Space>
                                                );
                                            }
                                        }}
                                    />
                                );
                            case "additional_notification_emails":
                                // json array string
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            console.log("value", value);
                                            if (isEditing(record.id)) {
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <Select
                                                            mode="tags"
                                                            style={{ width: "100%" }}
                                                            placeholder="Enter tags"
                                                            defaultValue={value?.length > 0 ? value : []}
                                                        />
                                                    </Form.Item>
                                                );
                                            } else {
                                                return (
                                                    <Space direction="vertical">
                                                        <div
                                                            style={{
                                                                // clutter tags
                                                                display: "flex",
                                                                flexWrap: "wrap",
                                                                width: "100%",
                                                                justifyContent: "space-between",
                                                                gap: "5px",
                                                            }}
                                                        >
                                                            {value?.map((item: string, i: number) => (
                                                                <Tag key={item}>{item}</Tag>
                                                            ))}
                                                        </div>
                                                    </Space>
                                                );
                                            }
                                        }}
                                    />
                                );
                            case "icp_description":
                                // if changed set promptUserWithLock to true other than that it should be stradart edit
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            if (isEditing(record.id)) {
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <TextareaAutosize
                                                            maxRows={3}
                                                            onChange={(e) => {
                                                                setPromptUserWithLock(true);
                                                            }}
                                                            style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #7A7A7A",
                                                                borderRadius: "6px",
                                                                width: "500px",
                                                                whiteSpace: "pre-wrap",
                                                                resize: "none",
                                                                overflow: "hidden",
                                                            }}
                                                            defaultValue={value}
                                                        />
                                                    </Form.Item>
                                                );
                                            } else {
                                                return (
                                                    <TextField
                                                        style={{ width: "200px" }}
                                                        value={value?.length > 200 ? value?.slice(0, 200) + "..." : value}
                                                    ></TextField>
                                                );
                                            }
                                        }}
                                    />
                                );
                            case "refresh_icp":
                                // a boolean which indicates if it automaticlly refreshed
                                // should render as a button which changes from 0 to 1 and vice versa
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            if (isEditing(record.id)) {
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <Select style={{ width: "100%" }} placeholder="Enter tags" defaultValue={value}>
                                                            <Select.Option value={1}>Activity Refreshing</Select.Option>
                                                            <Select.Option value={0}>Not Activity Refreshing</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            } else {
                                                return (
                                                    <TextField
                                                        style={{ fontWeight: "bold", width: "200px" }}
                                                        value={value ? "Activity Refreshing" : "Not Activity Refreshing"}
                                                    ></TextField>
                                                );
                                            }
                                        }}
                                    />
                                );
                            case "ai_reply_generation":
                                // a boolean which indicates if it automaticlly refreshed
                                // should render as a button which changes from 0 to 1 and vice versa
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            if (isEditing(record.id)) {
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <Select style={{ width: "100%" }} placeholder="Enter tags" defaultValue={value}>
                                                            <Select.Option value={1}>Activity Generating Replies</Select.Option>
                                                            <Select.Option value={0}>Not Generating Replies</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            } else {
                                                return (
                                                    <TextField
                                                        style={{ fontWeight: "bold", width: "200px" }}
                                                        value={value ? "Activity Refreshing" : "Not Activity Refreshing"}
                                                    ></TextField>
                                                );
                                            }
                                        }}
                                    />
                                );
                            case "auto_messages_active":
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            if (isEditing(record.id)) {
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <Select style={{ width: "100%" }} placeholder="Enter tags" defaultValue={value}>
                                                            <Select.Option
                                                                style={{
                                                                    backgroundColor: "#ffb619",
                                                                }}
                                                                value={1}
                                                            >
                                                                Sending Messages Automatically
                                                            </Select.Option>
                                                            <Select.Option value={0}>Not Sending Messages Automatically</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            } else {
                                                return (
                                                    <TextField
                                                        style={{
                                                            fontWeight: "bold",
                                                            width: "200px",
                                                            backgroundColor: value ? "#ffb619" : "#ffffff",
                                                        }}
                                                        value={value ? "Sending Messages Automatically" : "Not Sending Messages Automatically"}
                                                    ></TextField>
                                                );
                                            }
                                        }}
                                    />
                                );
                            default:
                                return (
                                    <Table.Column<IUserSettings>
                                        key={column.dataIndex}
                                        title={column.title}
                                        dataIndex={column.dataIndex}
                                        render={(value, record) => {
                                            if (isEditing(record.id)) {
                                                if (Array.isArray(value) || ARRAY_TYPE_COLUMNS.includes(column.dataIndex)) {
                                                    return (
                                                        <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                            <Select
                                                                mode="tags"
                                                                style={{ width: "100%" }}
                                                                placeholder="Tags Mode"
                                                                defaultValue={value?.length > 0 ? value : []}
                                                            />
                                                        </Form.Item>
                                                    );
                                                }

                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }}>
                                                        <TextareaAutosize
                                                            maxRows={3}
                                                            style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #7A7A7A",
                                                                borderRadius: "6px",
                                                                width: "500px",
                                                                whiteSpace: "pre-wrap",
                                                                resize: "none",
                                                                overflow: "hidden",
                                                            }}
                                                            defaultValue={value}
                                                        />
                                                    </Form.Item>
                                                );
                                            } else {
                                                if (Array.isArray(value) || ARRAY_TYPE_COLUMNS.includes(column.dataIndex)) {
                                                    return (
                                                        // render tags
                                                        <Space direction="vertical">
                                                            <div
                                                                style={{
                                                                    // clutter tags
                                                                    display: "flex",
                                                                    flexWrap: "wrap",
                                                                    width: "100%",
                                                                    justifyContent: "space-between",
                                                                    gap: "5px",
                                                                }}
                                                            >
                                                                {value?.map((item: string, i: number) => (
                                                                    <Tag color="blue" key={item}>
                                                                        {item}
                                                                    </Tag>
                                                                ))}
                                                            </div>
                                                        </Space>
                                                    );
                                                }

                                                return (
                                                    <TextField
                                                        style={{ width: "200px" }}
                                                        value={value?.length > 200 ? value?.slice(0, 200) + "..." : value}
                                                    ></TextField>
                                                );
                                            }
                                        }}
                                    />
                                );
                        }
                    })}
                    <Table.Column<IUserSettings>
                        key="actions"
                        title="Actions"
                        dataIndex="actions"
                        render={(value, record) => {
                            return (
                                <Space>
                                    {isEditing(record.id) && (
                                        <>
                                            <Popconfirm
                                                title="Confirm"
                                                description={
                                                    <div>
                                                        {promptUserWithLock ? (
                                                            <div>
                                                                <div>Seems like ICP description have changed</div>
                                                                <div>To confirm please input "{CONFIRMATION_STRING}"</div>
                                                                <Input
                                                                    value={confirmLockText}
                                                                    onChange={(e) => {
                                                                        setConfirmLockText(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div>Save Changes? </div>
                                                        )}
                                                    </div>
                                                }
                                                onConfirm={
                                                    promptUserWithLock
                                                        ? confirmLockText === CONFIRMATION_STRING
                                                            ? saveIcpDescriptionChanges
                                                            : () => {
                                                                  notification.error({
                                                                      message: "Invalid input",
                                                                      description: `Please input '${CONFIRMATION_STRING}' to confirm`,
                                                                  });
                                                              }
                                                        : saveButtonProps?.onClick
                                                }
                                                onCancel={cancelButtonProps?.onClick}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <SaveButton />
                                            </Popconfirm>

                                            <Button {...cancelButtonProps}>Cancel</Button>
                                        </>
                                    )}
                                </Space>
                            );
                        }}
                    />
                </Table>
                <div
                    style={{
                        padding: "0 50px",
                    }}
                >
                    <Card
                        style={{
                            width: "700px",
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            padding: "16px",
                            fontFamily: "Arial, sans-serif",
                        }}
                    >
                        <div style={{ fontSize: "24px", fontWeight: "bold", color: "#333", marginBottom: "16px" }}>User Details</div>
                        {USER_CARD_COLUMNS.map((column: any) => {
                            const value = tableProps?.dataSource?.[0]?.[column as keyof IUserSettings];
                            if (value) {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                            backgroundColor: "#ebf3ff",
                                            padding: "8px",
                                            borderRadius: "4px",
                                        }}
                                        key={column}
                                    >
                                        <div style={{ fontSize: "16px", color: "#555" }}>{column}</div>
                                        <div style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{value.toString()}</div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </Card>
                    {/* <PerformanceCard performanceStatistics={performanceStatistics} /> */}
                    <Card
                        style={{
                            width: "700px",
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            padding: "16px",
                            fontFamily: "Arial, sans-serif",
                            marginTop: "16px",
                        }}
                    >
                        <div style={{ fontSize: "24px", fontWeight: "bold", color: "#333", marginBottom: "16px" }}>ICP Description</div>
                        <div className="markdown-body">
                            <ReactMarkdown children={tableProps?.dataSource?.[0]?.icp_description || ""} />
                        </div>
                    </Card>
                    <Card
                        style={{
                            width: "700px",
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            padding: "16px",
                            fontFamily: "Arial, sans-serif",
                            marginTop: "16px",
                        }}
                    >
                        <div style={{ fontSize: "24px", fontWeight: "bold", color: "#333", marginBottom: "16px" }}>Denied Reasons</div>
                        {deniedReasons?.map((reason: { deny_type: DenyReasons; deny_values: string[] }) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                        backgroundColor: "#ebf3ff",
                                        padding: "8px",
                                        borderRadius: "4px",
                                    }}
                                    key={reason.deny_type}
                                >
                                    <div style={{ fontSize: "16px", color: "#555" }}>{DENIED_REASONS_MAP[reason.deny_type]}</div>
                                    {/* show tags and show values seperatly */}
                                    <div style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
                                        {reason?.deny_values?.map((value: any) => {
                                            return (
                                                <Tag color="blue" key={value}>
                                                    {value}
                                                </Tag>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </Card>
                </div>
            </Form>
        </List>
    );
};

export default UserSettings;
