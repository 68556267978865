import { Modal, Input, DatePicker, Spin, Button, Card } from "antd";
import { useEffect, useState } from "react";
import { message as notificationMessage } from "antd";

import axios from "axios";
import env from "config/site.config";

const TestTemplateModal = ({
    visible,
    onClose,
    content,
    badge,
    type,
    workflowFunction,
}: {
    visible: boolean;
    onClose: () => void;
    content: string;
    badge: string;
    type: any;
    workflowFunction: string;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingFields, setLoadingFields] = useState<boolean>(false);
    const [loadingMessageGeneration, setLoadingMessageGeneration] = useState<boolean>(false);
    // messages
    const [prevMessage, setPrevMessage] = useState<string>("");
    // templates
    const [chosenTemplate, setChosenTemplate] = useState<any>({
        id: "nnnnnnnn-7bd8-43ae-b010-98ac96055a25-dummy-value",
        badge_value: badge,
        template_name: `TESTING NEW FOR ${badge}`,
        type: 1,
        message: content,
        workflow_function: "EventWorkflow",
    });
    const [contentInputFill, setContentInputFill] = useState(false);
    const [generatedMessage, setGeneratedMessage] = useState<string>("");
    const [fieldsForTemplate, setFieldsForTemplate] = useState<any>([]);

    useEffect(() => {
        console.log("TestTemplateModal -> visible", visible);
        console.log("TestTemplateModal -> content", content);
        console.log("TestTemplateModal -> badge", badge);
        console.log("TestTemplateModal -> type", type);
        console.log("TestTemplateModal -> workflowFunction", workflowFunction);

        // if workflow function, get the required fields
        if (workflowFunction) {
            handleWorkflowTemplateChange({
                // create a fake template to get the required fields
                workflow_function: workflowFunction,
                message: content,
            });
        }
    }, [visible]);

    useEffect(() => {
        // LOGS REQUIRED FIELDS
        console.log("TestTemplateModal -> fieldsForTemplate", fieldsForTemplate);
    }, [fieldsForTemplate]);

    useEffect(() => {
        console.log("TestTemplateModal -> chosenTemplate", chosenTemplate);

        handleWorkflowTemplateChange({
            // create a fake template to get the required fields
            workflow_function: workflowFunction,
            message: content,
        });
    }, [workflowFunction]);

    const handleWorkflowTemplateChange = async (template: any) => {
        console.log("handleWorkflowTemplateChange -> template", template);
        const { message, message_2, summary } = template;

        if (!content) {
            return;
        }

        console.log("handleWorkflowTemplateChange -> selectedTemplate", template);
        setLoadingFields(true);
        try {
            const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/templates/workflow`, {
                params: {
                    workflow_name: template?.workflow_function,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            console.log("handleWorkflowTemplateChange -> response", response);

            const requiredFields = response?.data?.required_fields;

            console.log("handleWorkflowTemplateChange -> requiredFields", requiredFields);

            // set chosen template to the template but with the required fields, but no content input
            setChosenTemplate({ ...template, required_fields: requiredFields });
            setContentInputFill(false);
            setFieldsForTemplate(requiredFields);
        } catch (error) {
            console.error("Error fetching required fields:", error);
            badge !== "" && notificationMessage.error("Error fetching required fields");
        } finally {
            setLoadingFields(false);
        }
    };

    const generateMessage = async (template: any) => {
        console.log("generateMessage -> chosenTemplate:", chosenTemplate);
        console.log("generateMessage -> template:", template);
        console.log("generateMessage -> tempValue:", content);
        console.log("generateMessage -> fieldsForTemplate:", fieldsForTemplate);
        // dont generate if required fields are not filled
        if (loadingMessageGeneration) {
            notificationMessage.error("Please wait for the message to be generated");
            return;
        }
        if (fieldsForTemplate) {
            const requiredFields = template?.required_fields;
            console.log("requiredFields", requiredFields);
            const fieldsForTemplateKeys = Object.keys(fieldsForTemplate);
            console.log("fieldsForTemplateKeys", fieldsForTemplateKeys);
            let missingFields = [];

            if (Array.isArray(requiredFields)) {
                // Original logic for array
                missingFields = requiredFields.filter((field: any) => !fieldsForTemplateKeys.includes(field));
            } else {
                // New logic for object
                missingFields = Object.keys(requiredFields).filter((field: any) => !fieldsForTemplateKeys.includes(field));
            }

            if (missingFields.length > 0) {
                notificationMessage.error(`Please fill the required fields: ${missingFields.join(", ")}`);
                return;
            }
        }

        setLoadingMessageGeneration(true);
        try {
            // send request to generate message by template type
            console.log("generateMessage - >template?.type", template?.type);
            switch (template?.type) {
                case 0:
                    notificationMessage.error("Cannot generate message for hard template");
                    break;
                case 1:
                    await generateForWorkflowTemplate(template);
                    break;
                default:
                    await generateForWorkflowTemplate(template);
                    break;
            }
        } catch (error: any) {
            console.log(error);
            notificationMessage.error("Error generating message");
            setGeneratedMessage("");
        } finally {
            setLoadingMessageGeneration(false);
        }
    };

    const generateForWorkflowTemplate = async (template: any) => {
        if (!template) {
            notificationMessage.error("Please select a workflow function");
            return;
        }

        console.log("generateForWorkflowTemplate -> template", template);
        const { data } = await axios.post(
            `${env.REACT_APP_BACKEND_URL}/api/v1/templates/generate_message`,
            {
                workflow_name: template?.workflow_function,
                template_vars: fieldsForTemplate,
                template_string: content || "",
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
        );

        console.log("data", data);
        if (data?.message) {
            setGeneratedMessage(data.message);
        }
    };

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            // add custom button
            footer={[
                <Button
                    key="back"
                    className="ant-btn"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    className="ant-btn ant-btn-primary"
                    onClick={() => {
                        console.log("generatedMessage", generatedMessage, chosenTemplate);

                        generateMessage(chosenTemplate);
                    }}
                >
                    Test
                </Button>,
            ]}
        >
            <h1>Test Template</h1>

            <h3>Required Fields</h3>
            <div>
                {loadingFields ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <>
                        <strong>
                            <label htmlFor="fields">Required Fields for this template:</label>
                        </strong>
                        {
                            // Check if 'required_fields' is an array
                            Array.isArray(chosenTemplate?.required_fields)
                                ? // Handle array of strings as before
                                  chosenTemplate?.required_fields?.map((field: string) => (
                                      <div key={field}>
                                          <label htmlFor={field}>{field}</label>
                                          <Input
                                              style={{ width: "100%" }}
                                              onChange={(e) => {
                                                  setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                              }}
                                              placeholder={`Fill ${field}`}
                                          />
                                      </div>
                                  ))
                                : // Handle object of fields with data type
                                  Object.entries(chosenTemplate?.required_fields || {})?.map(([field, type]) => {
                                      let InputComponent;

                                      switch (type) {
                                          case "string":
                                              InputComponent = (
                                                  <Input
                                                      value={fieldsForTemplate?.[field] !== "string" ? fieldsForTemplate?.[field] : ""}
                                                      style={{ width: "100%" }}
                                                      onChange={(e) => {
                                                          setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                      }}
                                                      placeholder={`Fill ${field}`}
                                                  />
                                              );
                                              break;

                                          case "date":
                                              InputComponent = (
                                                  <DatePicker
                                                      style={{ width: "100%" }}
                                                      format="DD/MM/YY"
                                                      inputReadOnly={true}
                                                      onChange={(date: any, dateString: string) => {
                                                          setFieldsForTemplate((prev: any) => ({ ...prev, [field]: dateString }));
                                                      }}
                                                      placeholder={`Select ${field}`}
                                                  />
                                              );
                                              break;

                                          default:
                                              InputComponent = (
                                                  <Input
                                                      style={{ width: "100%" }}
                                                      onChange={(e) => {
                                                          setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                      }}
                                                      placeholder={`Fill ${field}`}
                                                  />
                                              );
                                              break;
                                      }

                                      return (
                                          <div key={field}>
                                              <label htmlFor={field}>{field}</label>
                                              {InputComponent}
                                          </div>
                                      );
                                  })
                        }
                    </>
                )}
                {loadingMessageGeneration ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <>
                        <strong>
                            <label htmlFor="message">Generated Message:</label>
                        </strong>
                        <Card title={"Test Output:"}>{generatedMessage}</Card>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default TestTemplateModal;
