import { useEffect, useRef, useState } from "react";
import { Tooltip, Button, Card } from "antd";

import { MinusCircleTwoTone, CloseCircleTwoTone, CheckCircleTwoTone, ExclamationCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import env from "config/site.config";
import axios from "axios";

const MessageVerification = ({
    message,
    record,
    isEditorOpen,
    handleAcceptSuggestion,
    invokeVerification,
    verifiedCallback,
    failedCallback,
}: {
    message: string;
    record: any;
    isEditorOpen: boolean;
    handleAcceptSuggestion: (suggestion: string) => void;
    invokeVerification: boolean | null;
    verifiedCallback: () => void;
    failedCallback: () => void;
}) => {
    const VerificationStatus = {
        UNVERIFIED: 0,
        VERIFIED: 1,
        FAILED: 2,
        ERROR: 3,
    };

    const [isVerified, setIsVerified] = useState(record?.message_checked ? VerificationStatus.VERIFIED : VerificationStatus.UNVERIFIED);
    const [loading, setLoading] = useState(false);
    const prevMessageRef = useRef<string | null>(null);

    const [verificationDetails, setVerificationDetails] = useState<any>(null);

    useEffect(() => {
        if (invokeVerification === null) return;
        console.log("Checking if the verification needs to be invoked");
        if (invokeVerification) {
            console.log("Invoking the verification");
            verifyMessage();
        }
    }, [invokeVerification]);

    useEffect(() => {
        console.log("Checking editor state");
        if (isEditorOpen && prevMessageRef.current === null) {
            console.log("Saving the initial message as the editor is opened", message);
            prevMessageRef.current = message;
        }
    }, [isEditorOpen]);

    useEffect(() => {
        console.log("Message value has been updated");
        if (message !== prevMessageRef.current && !isEditorOpen) {
            console.log("Message has changed from the initial value. Verifying...");
            console.log("Previous message:", prevMessageRef.current);
            console.log("Current message:", message);

            // if prevMessageRef.current is null, it means that the editor was never opened , abort the verification
            if (prevMessageRef.current === null) {
                console.log("Editor was never opened, aborting the verification");
                return;
            }

            verifyMessage();
        }
    }, [message]);

    const verifyMessage = async () => {
        console.log("Verifying the message...");
        setLoading(true);
        try {
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/check_message`,
                {
                    message: message,
                    leadId: record?.id,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
            console.log("Message verification response:", data);
            if (data && data.approved) {
                setIsVerified(VerificationStatus.VERIFIED);
                if (verifiedCallback) verifiedCallback();
            } else {
                setIsVerified(VerificationStatus.FAILED);
                setVerificationDetails(data);
                if (failedCallback) failedCallback();
            }
        } catch (error) {
            console.error("Error occurred while verifying the message:", error);
            setIsVerified(VerificationStatus.ERROR);
            if (failedCallback) failedCallback();
        } finally {
            setLoading(false);
        }
    };

    const renderTooltipContent = () => {
        switch (isVerified) {
            case VerificationStatus.VERIFIED:
                return "No mistakes found";
            case VerificationStatus.FAILED:
                // Highlight the words wrapped with {{}} in green and underline
                const transformedSuggestion = verificationDetails.suggestion.replace(/\{\{(\w+?)\}\}/g, '<u style="color: #52c41a;">$1</u>');
                return (
                    <div>
                        <p>{verificationDetails.explanation}</p>
                        <Card bordered={true} style={{ width: "100%", backgroundColor: "white" }}>
                            {/* Rendering the transformed suggestion safely */}
                            <p>
                                Suggestion: <span dangerouslySetInnerHTML={{ __html: transformedSuggestion }}></span>
                            </p>
                            <Button
                                onClick={() => {
                                    handleAcceptSuggestion(
                                        verificationDetails.suggestion.replace(/\{\{([^}]+?)\}\}/g, "$1") // Removing the {{}} from the suggestion
                                    );
                                }}
                            >
                                Accept Suggestion
                            </Button>
                        </Card>
                    </div>
                );
            default:
                return "";
        }
    };

    if (loading) {
        return (
            <div>
                <LoadingOutlined />
            </div>
        );
    }

    const verificationIcons = {
        [VerificationStatus.UNVERIFIED]: <MinusCircleTwoTone twoToneColor={"#d8d8d8"} />,
        [VerificationStatus.VERIFIED]: <CheckCircleTwoTone twoToneColor={"#52c41a"} />,
        [VerificationStatus.FAILED]: <CloseCircleTwoTone twoToneColor={"red"} />,
        [VerificationStatus.ERROR]: <ExclamationCircleTwoTone twoToneColor={"yellow"} />,
    };

    return (
        <Tooltip title={renderTooltipContent()} placement="top">
            <div>{verificationIcons[isVerified] || verificationIcons[VerificationStatus.UNVERIFIED]}</div>
        </Tooltip>
    );
};

export default MessageVerification;
