import React from "react";
import { Card } from "@pankod/refine-antd";

const ExternalTools = () => {
    const externalTools = [
        {
            title: "Podcast Analyser",
            description: "Get podcasts episodes",
            link: "http://44.203.204.103/",
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <h1>External Tools</h1>
            <div>
                {externalTools.map((tool, index) => {
                    return (
                        <Card title={tool.title} bordered={false} style={{ width: 300 }} key={index}>
                            <p>{tool.description}</p>
                            <a href={tool.link} target="_blank" rel="noopener noreferrer">
                                Go to tool
                            </a>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default ExternalTools;
