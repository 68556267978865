import React, { useEffect, useMemo, useState } from "react";
import routerProvider, { useNavigate } from "@pankod/refine-react-router-v6";
import axios from "axios";

import env from "config/site.config";
import {
    List,
    Table,
    Form,
    Space,
    Button,
    SaveButton,
    Collapse,
    EditButton,
    Input,
    TextField,
    useEditableTable,
    Tag,
    Modal,
    Select,
    Segmented,
    Card,
    message,
    Spin,
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IAdminSettings } from "interfaces";
import { ADMIN_SETTINGS_COLUMNS } from "utils/constants";
import { PlusOutlined } from "@ant-design/icons";
import MessageTemplates from "components/MessageTemplates";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { HardTemplateForm, LinkTemplateForm, SoftTemplateForm } from "../../components/templateModalsForms";

const { Panel } = Collapse;
const { Option } = Select;

const AdminConfig: React.FC<IResourceComponentsProps> = () => {
    const {
        tableProps,
        formProps,
        isEditing,
        setId: setEditId,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
    } = useEditableTable<IAdminSettings>({ resource: `admin_settings` });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCouponModalOpen, setCouponModalOpen] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [newTemplateArgs, setNewTemplateArgs] = useState({
        badge_id: "",
        message: "",
        type: 0,
        content: "", // only for testing the template
        required_fields: [],
        fields: null,
    });

    const adminId = tableProps.dataSource?.[0]?.id;
    const [localBadges, setLocalBadges] = useState([]);
    const [admins, setAdmins] = useState<any[]>([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [loadingTestOutput, setLoadingTestOutput] = useState(false);
    const [testOutput, setTestOutput] = useState("");

    useEffect(() => {
        const badgesJSON: string | undefined = tableProps.dataSource?.[0]?.badges_options as string | undefined;
        const badges = badgesJSON ? JSON.parse(JSON.stringify(badgesJSON)) : [];
        console.log("badges", badges);
        setLocalBadges(badges);
    }, [tableProps.dataSource]);

    const createTemplate = async () => {
        console.log("newTemplateArgs", newTemplateArgs);

        if (!newTemplateArgs.badge_id) {
            message && message.error("Please fill all fields");
            return;
        }

        if (!newTemplateArgs.message) {
            message && message.error("Please Generate a Template before saving");
            return;
        }

        // make sure the badge_id is a string and does not contain "/" or any other special characters that can interfiere with the url
        if (typeof newTemplateArgs.badge_id !== "string") {
            message && message.error("Badge must be a string");
            return;
        }

        if (newTemplateArgs.badge_id.includes("/")) {
            message && message.error("Badge cannot contain '/'");
            return;
        }

        const badgeValue = newTemplateArgs.badge_id;

        try {
            setIsModalVisible(false);
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/message_templates/add/${badgeValue}`,
                newTemplateArgs,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            console.log("data", data);
            if (data?.error) {
                message && message.error(data?.error);
                return;
            }
            setLocalBadges(data);
            message && message.success("Template created successfully");
        } catch (error) {
            console.log("error", error);
            message && message.error("Error creating template");
        }
    };

    const handleDelete = async (id: number) => {
        try {
            const { data } = await axios.delete(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/message_templates/delete/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            console.log("data", data);
            if (data?.error) {
                message && message.error(data?.error);
                return;
            }
            // update the local badges to not include the deleted one
            setLocalBadges(data);
            message && message.success("Template deleted successfully");
        } catch (error) {
            console.log("error", error);
            message && message.error("Error deleting template");
        }
    };

    const handleGenerateClick = async () => {
        if (!newTemplateArgs?.message) {
            message && message.error("Please fill all fields");
            return;
        }
        if (!newTemplateArgs?.content) {
            message && message.error("Please fill all fields");
            return;
        }
        try {
            setLoadingTestOutput(true);
            setNewTemplateArgs({
                ...newTemplateArgs,
            });
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/generate_message`,
                {
                    message: newTemplateArgs?.message,
                    content: newTemplateArgs?.content,
                    template: newTemplateArgs,
                    fields: newTemplateArgs?.fields,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (data?.error) {
                message && message.error(data?.error);
                return;
            }
            console.log("data", data);
            setTestOutput(data?.message);
        } catch (error) {
            console.log("error", error);
        } finally {
            setLoadingTestOutput(false);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        await createTemplate();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        console.log("newTemplateArgs", newTemplateArgs);
    }, [newTemplateArgs]);

    const getAdmins = async () => {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/roles/admins`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                    user: localStorage.getItem("user")?.toString() || "",
                },
            });
            console.log("admins data", data);
            setAdmins(data);
        } catch (error) {
            console.log("error", error);
            message && message.error("Error fetching admins");
        }
    };

    useEffect(() => {
        getAdmins();
    }, []);

    const renderMessageTemplates = () => {
        return (
            <Collapse bordered={false}>
                {localBadges?.map((badge: any) => {
                    return (
                        <Panel
                            style={{
                                width: "100%",
                                backgroundColor: "white",
                            }}
                            header={<Tag color={badge.color}>{badge.value}</Tag>}
                            key={badge.id}
                        >
                            <div
                                style={{
                                    margin: "20px",
                                }}
                            >
                                {badge.templates && <MessageTemplates handleDelete={handleDelete} templates={badge.templates} />}
                            </div>
                            <Button
                                shape="round"
                                type="primary"
                                icon={<PlusOutlined />}
                                size="small"
                                onClick={() => {
                                    setNewTemplateArgs({
                                        ...newTemplateArgs,
                                        badge_id: badge.value,
                                    });
                                    showModal();
                                }}
                            >
                                Add new template
                            </Button>
                        </Panel>
                    );
                })}
            </Collapse>
        );
    };

    const removeAdmin = (admin: any) => {
        Modal.confirm({
            title: "Are you sure you want to remove this admin?",
            icon: <ExclamationCircleOutlined />,
            content: `Admin: ${admin.name} will be removed`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                removeAdminAccess(admin);
            },
        });
    };

    const addAdmin = (user: any) => {
        Modal.confirm({
            title: "Are you sure you want to add this user as admin?",
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "primary",
            cancelText: "No",
            onOk() {
                // if user is not already in admin list

                const alreadyAdmin = admins.find((admin: any) => admin.id === user.id);

                if (alreadyAdmin) {
                    message && message.error("User is already admin");
                    return;
                }

                addAdminAccess(user);
                // refresh the admins list
            },
        });
    };

    const removeAdminAccess = async (admin: any) => {
        // also pass the email of who is removing the admin

        try {
            const { data } = await axios.delete(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/roles/admins/${admin?.id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            console.log("data", data);
            if (data?.error) {
                message && message.error(data?.error);
                return;
            }
            setAdmins(admins.filter((currentAdmin: any) => currentAdmin.id !== admin.id));
            message && message.success("Admin removed successfully");
        } catch (error) {
            console.log("error", error);
            message && message.error("Cannot remove admin");
        }
    };

    const addAdminAccess = async (user: any) => {
        // also pass the email of who is adding the admin

        try {
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/roles/admins/${user?.id}`,
                {},
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                        user: localStorage.getItem("user")?.toString() || "",
                    },
                }
            );
            if (data?.error) {
                message && message.error(data?.error);
                return;
            }
            console.log("data", data);
            message && message.success("Admin added successfully");
            setAdmins([...admins, user]);
            setSelectedUser(null);
        } catch (error) {
            console.log("error", error);
            message && message.error("Cannot add admin");
        }
    };

    const fetchUsers = async (searchValue: string) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/search/users`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                    user: localStorage.getItem("user")?.toString() || "",
                },
                params: {
                    searchValue,
                },
            });
            console.log("found users", response.data);

            setUsers(response.data);
        } catch (error) {
            console.log("Error fetching users:", error);
            message && message.error("Error fetching users");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <List>
            <div>
                <Card>
                    <div>
                        <h2>Current Admins:</h2>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {admins.map((admin: any, index) => {
                                return (
                                    <div key={index} style={{ margin: "5px" }}>
                                        <Tag
                                            color="green"
                                            closable
                                            onClose={(e) => {
                                                e.preventDefault();
                                                removeAdmin(admin);
                                            }}
                                        >
                                            {admin?.name}
                                        </Tag>
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Select
                                // showSearch
                                style={{ width: 200 }}
                                loading={isLoading}
                                // onSearch={fetchUsers}
                                onFocus={() => fetchUsers("")}
                                onChange={(value) => {
                                    const userToSet = users.find((user: any) => user.id === value);

                                    if (userToSet) {
                                        setSelectedUser(userToSet);
                                    }
                                }}
                            >
                                {users.map((user: any) => (
                                    <Option key={user.id} value={user.id}>
                                        {user.name}
                                    </Option>
                                ))}
                            </Select>

                            <Button type="primary" onClick={() => selectedUser && addAdmin(selectedUser)} disabled={!selectedUser}>
                                Add Admin
                            </Button>
                        </div>
                    </div>
                </Card>
                <Card>
                    <h2>General Actions</h2>
                    <div
                        style={{
                            display: "flex",
                            gap: "2rem",
                        }}
                    >
                        <Button
                            onClick={async () => {
                                const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/coupon_code/generate`, {
                                    headers: {
                                        authorization: `Bearer ${localStorage.getItem("token")}`,
                                    },
                                });
                                console.log("data", data);

                                if (data?.error) {
                                    message && message.error(data?.error);
                                    return;
                                }
                                message && message.success(`Coupon code generated successfully: ${data?.coupon_code}`);
                                setCouponCode(data?.coupon_code);
                                setCouponModalOpen(true);
                            }}
                        >
                            Generate Coupon Code
                        </Button>
                        <SyncActiveCampaigns />
                    </div>
                    <Modal
                        title="Coupon Code"
                        open={isCouponModalOpen}
                        footer={
                            <Button
                                onClick={() => {
                                    setCouponModalOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        }
                        onCancel={() => {
                            setCouponModalOpen(false);
                        }}
                    >
                        <div>{couponCode}</div>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(couponCode);
                                message && message.success("Copied to clipboard");
                            }}
                        >
                            Copy to clipboard
                        </Button>
                    </Modal>
                </Card>
                <Form {...formProps}>
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => ({
                            // eslint-disable-next-line
                            onClick: (event: any) => {
                                if (event.target.nodeName === "TD") {
                                    setEditId && setEditId(record.id);
                                }
                            },
                        })}
                    >
                        {ADMIN_SETTINGS_COLUMNS?.map((column) => {
                            switch (column.dataIndex) {
                                case "id":
                                    return (
                                        <Table.Column<IAdminSettings>
                                            key={column.dataIndex}
                                            title={column.title}
                                            dataIndex={column.dataIndex}
                                            render={(value, record) => {
                                                return value;
                                            }}
                                        />
                                    );
                                case "config_name":
                                    return (
                                        <Table.Column<IAdminSettings>
                                            key={column.dataIndex}
                                            title={column.title}
                                            dataIndex={column.dataIndex}
                                            render={(value, record) => {
                                                return value;
                                            }}
                                        />
                                    );
                                case "badges_options":
                                    return (
                                        <Table.Column<IAdminSettings>
                                            key={column.dataIndex}
                                            title={column.title}
                                            dataIndex={column.dataIndex}
                                            render={(value, record: any) => {
                                                if (isEditing(record.id)) {
                                                    const badges = value?.map((item: any) => item);
                                                    console.log(badges);

                                                    return (
                                                        <Form.Item name={column.dataIndex}>
                                                            <Select
                                                                defaultValue={badges}
                                                                mode="tags"
                                                                style={{ width: "100%" }}
                                                                tokenSeparators={[","]}
                                                            ></Select>
                                                        </Form.Item>
                                                    );
                                                }
                                                return (
                                                    <>
                                                        {value?.map((item: any) => {
                                                            return (
                                                                <Tag key={item.value} color={item.color}>
                                                                    {item.value}
                                                                </Tag>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            }}
                                        />
                                    );
                                case "admin_badges":
                                    return (
                                        <Table.Column<IAdminSettings>
                                            key={column.dataIndex}
                                            title={column.title}
                                            dataIndex={column.dataIndex}
                                            render={(value, record: any) => {
                                                if (isEditing(record.id)) {
                                                    const badges = value?.map((item: any) => item);
                                                    console.log(badges);

                                                    return (
                                                        <Form.Item name={column.dataIndex}>
                                                            <Select
                                                                defaultValue={badges}
                                                                mode="tags"
                                                                style={{ width: "100%" }}
                                                                tokenSeparators={[","]}
                                                            ></Select>
                                                        </Form.Item>
                                                    );
                                                }
                                                return (
                                                    <>
                                                        {value?.map((item: any) => {
                                                            return (
                                                                <Tag key={item.value} color={item.color}>
                                                                    {item.value}
                                                                </Tag>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            }}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })}
                        <Table.Column<IAdminSettings>
                            key="actions"
                            title="Actions"
                            dataIndex="actions"
                            render={(value, record) => {
                                return (
                                    <Space>
                                        {isEditing(record.id) && (
                                            <>
                                                <SaveButton {...saveButtonProps} />
                                                <Button {...cancelButtonProps}>Cancel</Button>
                                            </>
                                        )}
                                    </Space>
                                );
                            }}
                        />
                    </Table>
                </Form>
                <div
                    style={{
                        width: "100%",
                    }}
                >
                    <AddNewTemplateModal
                        isModalVisible={isModalVisible}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                        newTemplateArgs={newTemplateArgs}
                        setNewTemplateArgs={setNewTemplateArgs}
                        handleGenerateClick={handleGenerateClick}
                        loadingTestOutput={loadingTestOutput}
                        testOutput={testOutput}
                    />
                    {renderMessageTemplates()}
                </div>
            </div>
        </List>
    );
};

const AddNewTemplateModal = ({
    isModalVisible,
    handleOk,
    handleCancel,
    newTemplateArgs,
    setNewTemplateArgs,
    handleGenerateClick,
    loadingTestOutput,
    testOutput,
}: {
    isModalVisible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    newTemplateArgs: any;
    setNewTemplateArgs: any;
    handleGenerateClick: () => void;
    loadingTestOutput: boolean;
    testOutput: string;
}) => {
    const [templateType, setTemplateType] = useState<TemplateKeyType>(0);
    type TemplateKeyType = 0 | 1 | 2;

    const getFormComponent = () => {
        switch (templateType) {
            case 0:
                return <HardTemplateForm setNewTemplateArgs={setNewTemplateArgs} newTemplateArgs={newTemplateArgs} />;
            case 1:
                return (
                    <div>
                        <LinkTemplateForm
                            setNewTemplateArgs={setNewTemplateArgs}
                            newTemplateArgs={newTemplateArgs}
                            handleGenerateClick={handleGenerateClick}
                        />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <SoftTemplateForm
                            setNewTemplateArgs={setNewTemplateArgs}
                            newTemplateArgs={newTemplateArgs}
                            handleGenerateClick={handleGenerateClick}
                            loadingTestOutput={loadingTestOutput}
                            testOutput={testOutput}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal title="Add New Template" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Segmented
                onChange={(newValue: any) => {
                    setTemplateType(newValue);

                    let resetTemplateArgs = { type: newValue, badge_id: newTemplateArgs.badge_id };
                    setNewTemplateArgs(resetTemplateArgs);
                    console.log("resetTemplateArgs", resetTemplateArgs);
                }}
                block
                options={[
                    {
                        label: "Hard Template",
                        value: 0,
                    },
                    // {
                    //     label: "Link Template",
                    //     value: 1,
                    // },
                    {
                        label: "Soft Template",
                        value: 2,
                    },
                ]}
            />
            <Form.Item label="Badge" name="badge_i">
                <div>{newTemplateArgs.badge_id}</div>
            </Form.Item>{" "}
            {getFormComponent()}
        </Modal>
    );
};

export default AdminConfig;

function SyncActiveCampaigns() {
    const [loading, setLoading] = useState(false);

    return (
        <Button
            onClick={async () => {
                setLoading(true);
                message && message.info(`Syncing all active Expandi campaigns`);
                const { data } = await axios.post(
                    `${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/sync-active-campaigns`,
                    {},
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                console.log("data", data);

                if (data?.error) {
                    message && message.error(data?.error);
                    return;
                }
                setLoading(false);
                message && message.success(`Synced successfully`);
            }}
        >
            {loading ? <Spin /> : "Sync all active Expandi campaigns"}
        </Button>
    );
}
