import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import env from "config/site.config";
import { Select, Tag } from "@pankod/refine-antd";
import { ITemplate } from "interfaces";
import TestTemplateModal from "../TestTemplateModal";

const { Option } = Select;

const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

const AddTemplatePage = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [badgesOptions, setBadgesOptions] = useState<any[]>([]);
    const [chosenBadge, setChosenBadge] = useState<string>("");
    const [chosenBadgeType, setChosenBadgeType] = useState<number>();
    const [requiredFields, setRequiredFields] = useState<any[]>([]);
    const [enableWorkflowType, setEnableWorkflowType] = useState<boolean>(false);
    const [contentValue, setContentValue] = useState("");
    const [workflowOptions, setWorkflowOptions] = useState<any[]>([]);
    const [savedWorkflowFunction, setSavedWorkflowFunction] = useState<string>("");
    const [showTestModal, setShowTestModal] = useState(false);

    useEffect(() => {
        getBadgesOptions()
            .then((res) => {
                setBadgesOptions(res);
                // set workflow options to all the possible workflow templates
                const workflowOptions = res
                    .map((badge: any) => badge?.templates)
                    .flat()
                    .filter((template: ITemplate) => template?.workflow_function && template?.workflow_function !== "" && template.type === 1);
                const workflowOptionsNoDuplicates = workflowOptions?.filter(
                    (template: ITemplate, index: number, self: ITemplate[]) =>
                        index === self.findIndex((t: ITemplate) => t.workflow_function === template.workflow_function)
                );
                setWorkflowOptions(workflowOptionsNoDuplicates);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error occurred while fetching the badges options");
            });
    }, []);

    useEffect(() => {
        if (!chosenBadge || !chosenBadgeType) return;
        console.log("form.getFieldValue('badge')", form.getFieldValue("badge"));

        if (form.getFieldValue("badge")) {
            const badge = badgesOptions.find((badge) => badge.value === form.getFieldValue("badge"));
            if (badge && badge?.workflow_function) {
                handleWorkflowTemplateChange(badge?.workflow_function);
            }
        } else {
            message.error("Please select a badge to see workflow template fields");
        }
    }, [chosenBadgeType]);

    async function getBadgesOptions() {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings`, config);
            if (data?.length > 0) {
                return data[0]?.badges_options;
            }
        } catch (error) {
            message.error("Error occurred while fetching the badges options");
            console.log(error);
        }
    }

    const handleWorkflowTemplateChange = async (templateName: string) => {
        try {
            const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/templates/workflow`, {
                params: {
                    workflow_name: templateName,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const requiredFieldsData = response?.data?.required_fields;
            console.log("requiredFieldsData", requiredFieldsData);

            const requiredFields = Object.keys(requiredFieldsData);

            setRequiredFields(requiredFields);
        } catch (error) {
            console.error("Error occurred while fetching required fields:", error);
        }
    };

    const onSubmit = async (values: any) => {
        if (chosenBadgeType === 1) {
            for (const field of requiredFields) {
                if (!values.content.includes(field)) {
                    message.error(`Please include the ${field} placeholder in the content.`);
                    return;
                }
            }
        }

        try {
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/templates`,
                {
                    type: values.type,
                    template_name: values.template_name,
                    message: values.content,
                    badge_value: values.badge,
                    workflow_function: savedWorkflowFunction,
                },
                config
            );
            if (data && data.id) {
                message.success("Template added successfully");
                navigate("/templates");
            } else {
                message.error("Error occurred while adding the template");
            }
        } catch (error) {
            console.error("Error occurred while verifying the message:", error);
            message.error("Error occurred while verifying the message");
        } finally {
            form.resetFields();
        }
    };

    useEffect(() => {
        console.log("chosenBadge", chosenBadge);
        const workflowFunction = findWorkflowFunction(chosenBadge);
    }, [chosenBadge]);

    useEffect(() => {
        console.log("savedWorkflowFunction", savedWorkflowFunction);
        if (savedWorkflowFunction) {
            handleWorkflowTemplateChange(savedWorkflowFunction);
        }
    }, [savedWorkflowFunction]);

    const findWorkflowFunction = (badgeValue: string) => {
        const badge = badgesOptions.find((badge) => badge.value === badgeValue);

        const templates = badge?.templates;

        const templateOptions = templates?.filter(
            (template: ITemplate) =>
                template?.workflow_function && template?.workflow_function !== "" && template.type === 1 && template.badge_value === badgeValue
        );

        const templateOptionsNoDuplicates = templateOptions?.filter(
            (template: ITemplate, index: number, self: ITemplate[]) =>
                index === self.findIndex((t: ITemplate) => t.workflow_function === template.workflow_function)
        );

        if (templateOptionsNoDuplicates?.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleFieldClick = (field: string) => {
        const updatedContent = contentValue + "{" + field + "}";
        setContentValue(updatedContent);
        form.setFieldsValue({ content: updatedContent }); // Also update the form value
    };

    return (
        <div>
            <TestTemplateModal
                visible={showTestModal}
                onClose={() => setShowTestModal(false)}
                content={contentValue}
                badge={chosenBadge}
                type={chosenBadgeType}
                workflowFunction={savedWorkflowFunction}
            />
            <h1>Add Template</h1>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item label="Template name" name="template_name" rules={[{ required: true, message: "Please input the template_name!" }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Badge" name="badge" rules={[{ required: true, message: "Please input the badge!" }]}>
                    <Select
                        onChange={(value: string) => {
                            setChosenBadge(value);
                        }}
                        placeholder="Select a badge"
                        allowClear
                        showSearch
                    >
                        {badgesOptions.map((badge) => (
                            <Option key={badge?.value} value={badge?.value} color={badge?.color}>
                                <Tag color={badge?.color}>{badge?.value}</Tag>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Type" name="type" rules={[{ required: true, message: "Please input the type!" }]}>
                    <Select
                        onChange={(value: number) => {
                            console.log("value", value);
                            if (value === 1) {
                                setEnableWorkflowType(true);
                            } else {
                                setEnableWorkflowType(false);
                            }
                            setChosenBadgeType(value);
                        }}
                        placeholder="Select a type"
                        allowClear
                    >
                        <Option value={0}>Hard</Option>
                        <Option value={1}>Workflow</Option>
                    </Select>
                </Form.Item>

                {enableWorkflowType && workflowOptions?.length > 0 && (
                    <Form.Item label="Workflow template" name="workflow_function" rules={[{ required: false, message: "Please input the type!" }]}>
                        <Select
                            onChange={(value: any) => {
                                // handleWorkflowFunctionChange(value);
                                setSavedWorkflowFunction(value);
                            }}
                            placeholder="Select a type"
                            allowClear
                        >
                            {workflowOptions.map((template) => (
                                <Option key={template?.id} value={template?.workflow_function}>
                                    {template?.workflow_function}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item label="Content" name="content" rules={[{ required: true, message: "Please input the content!" }]}>
                    {enableWorkflowType && requiredFields.length > 0 && (
                        <div>
                            <h3>Required fields:</h3>
                            <ul>
                                {requiredFields.map((field, idx) => (
                                    <Tag
                                        key={idx}
                                        color="blue"
                                        style={{ cursor: "pointer", marginBottom: "10px" }}
                                        onClick={() => handleFieldClick(field)}
                                    >
                                        {"{" + field + "}"}
                                    </Tag>
                                ))}
                            </ul>
                        </div>
                    )}
                    <Input.TextArea
                        value={contentValue}
                        onChange={(e: any) => {
                            setContentValue(e.target.value);
                            form.setFieldsValue({ content: e.target.value });
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <div
                        style={{
                            display: "flex",

                            gap: "10px",
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button disabled={contentValue ? false : true} type="primary" onClick={() => setShowTestModal(true)}>
                            Test
                        </Button>
                        <Button htmlType="button" onClick={() => navigate("/templates")}>
                            Back
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddTemplatePage;
