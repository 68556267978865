import React, { useEffect, useState } from "react";
import { message as notificationMessage } from "antd"; // Rename the import here
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, Select, Tooltip, Input, DatePicker, Alert, notification, Spin, Modal } from "antd";

import env from "config/site.config";
import { ILead, ITemplate } from "interfaces";
import { TextareaAutosize } from "@pankod/refine-mui";
import { RedoOutlined } from "@ant-design/icons";
import { useCampaignData } from "context/campaignData";
import MessageVerification from "components/MessageVerification";

const { Option } = Select;

const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
};

const EditLeadsMessage = () => {
    const navigate = useNavigate();
    // general
    const { id } = useParams();
    const [lead, setLead] = useState<ILead>({} as ILead);
    const { campaignId, campaignData } = useCampaignData();
    //loading
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingFields, setLoadingFields] = useState<boolean>(false);
    const [loadingMessageGeneration, setLoadingMessageGeneration] = useState<boolean>(false);
    // messages
    const [message, setMessage] = useState<string>("");
    const [prevMessage, setPrevMessage] = useState<string>("");
    const [invokeVerification, setInvokeVerification] = useState<boolean | null>(null);
    // templates
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [chosenTemplate, setChosenTemplate] = useState<any>(null);
    const [contentInputFill, setContentInputFill] = useState(false);
    const [generatedMessage, setGeneratedMessage] = useState<string>("");
    const [fieldsForTemplate, setFieldsForTemplate] = useState<any>(null);
    const [hoverPlaceholder, setHoverPlaceholder] = useState<string>("");
    const [userApproveCharLimitCancel, setUserApproveCharLimitCancel] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchLead = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/campaign/lead/${id}`, config);
                console.log("data", data);
                setMessage(data?.message);
                setPrevMessage(data?.message);
                setLead(data);
            } catch (err) {
                notificationMessage.error("Failed to fetch lead!");
            } finally {
                setLoading(false);
            }
        };

        fetchLead();
    }, [id]);

    // get templates for the lead

    const onSubmit = async (message: string) => {
        // message verification
        console.log("onSubmit -> message", message);

        try {
            await axios.put(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/lead/${id}`,
                {
                    message: message,
                    template_id: chosenTemplate?.id,
                    template_name: chosenTemplate?.template_name,
                },
                config
            );
            notificationMessage.success("Lead's message updated successfully!");
            navigate("/campaign");
            // trigger message validation
        } catch (err) {
            notificationMessage.error("Failed to update lead's message!");
        }
    };

    useEffect(() => {
        const fetchTemplates = async () => {
            if (lead?.badges?.length > 0) {
                // get all templates for the badges
                try {
                    const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/templates?badge_value=${lead.badges}`, config);
                    console.log("response", data);

                    setTemplates(data);
                } catch (err) {
                    notificationMessage.error("Failed to fetch templates!");
                }
            }
        };

        fetchTemplates();
    }, [lead]);

    const handleHardTemplateChange = (template: any) => {
        const { message, message_2, summary } = template;

        // last name can have spaces, so split it
        const first_name = lead?.name?.split(" ")[0];
        const last_name = lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ").slice(1).join(" ") : "";
        const company_name = lead?.company_name;

        const newMessage = message.replace("{first_name}", first_name).replace("{last_name}", last_name).replace("{company_name}", company_name);

        setMessage(newMessage);
        setContentInputFill(false);
        setChosenTemplate(template);
    };

    const handleSoftTemplateChange = (template: any) => {
        const { message, message_2, summary } = template;

        const first_name = lead?.name?.split(" ")[0];
        const last_name = lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ").slice(1).join(" ") : "";
        const company_name = lead?.company_name;

        const newMessage = message.replace("{first_name}", first_name).replace("{last_name}", last_name).replace("{company_name}", company_name);

        setMessage(newMessage);
        setContentInputFill(true);
        setChosenTemplate(template);
    };

    const handleWorkflowTemplateChange = async (template: any) => {
        console.log("handleWorkflowTemplateChange -> template", template);
        const { message, message_2, summary } = template;

        console.log("handleWorkflowTemplateChange -> selectedTemplate", template);
        setLoadingFields(true);
        try {
            const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/templates/workflow`, {
                params: {
                    workflow_name: template?.workflow_function,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            console.log("handleWorkflowTemplateChange -> response", response);

            const requiredFields = response?.data?.required_fields;

            console.log("handleWorkflowTemplateChange -> requiredFields", requiredFields);

            // set chosen template to the template but with the required fields, but no content input
            setChosenTemplate({ ...template, required_fields: requiredFields });
            setContentInputFill(false);
            setFieldsForTemplate(null);
        } catch (error) {
            console.error("Error fetching required fields:", error);
            notificationMessage.error("Error fetching required fields");
        } finally {
            setLoadingFields(false);
        }
    };

    const handleTemplateChange = (value: number | undefined, templates: any, selectedTemplate: any) => {
        console.log("handleTemplateChange -> value", value);

        const template = templates.find((item: any) => item.type === value && item.id === selectedTemplate?.id);
        if (template) {
            const templateType = template?.type;

            console.log("templateType", templateType);
            console.log("template", template);
            console.log("chosenTemplate", chosenTemplate);

            switch (templateType) {
                case 0:
                    handleHardTemplateChange(template);
                    break;
                case 1:
                    handleWorkflowTemplateChange(template);
                    break;
                case 2:
                    handleSoftTemplateChange(template);
                    break;
            }
        } else {
            setMessage("");
        }
    };

    const generateMessage = async (template: any) => {
        console.log("generateMessage -> chosenTemplate:", chosenTemplate);
        console.log("generateMessage -> template:", template);
        console.log("generateMessage -> tempValue:", message);
        console.log("generateMessage -> fieldsForTemplate:", fieldsForTemplate);
        // dont generate if required fields are not filled
        if (loadingMessageGeneration) {
            notificationMessage.error("Please wait for the message to be generated");
            return;
        }
        if (fieldsForTemplate) {
            const requiredFields = template?.required_fields;
            console.log("requiredFields", requiredFields);
            const fieldsForTemplateKeys = Object.keys(fieldsForTemplate);
            console.log("fieldsForTemplateKeys", fieldsForTemplateKeys);
            let missingFields = [];

            if (Array.isArray(requiredFields)) {
                // Original logic for array
                missingFields = requiredFields.filter((field: any) => !fieldsForTemplateKeys.includes(field));
            } else {
                // New logic for object
                missingFields = Object.keys(requiredFields).filter((field: any) => !fieldsForTemplateKeys.includes(field));
            }

            if (missingFields.length > 0) {
                notificationMessage.error(`Please fill the required fields: ${missingFields.join(", ")}`);
                return;
            }
        }

        setLoadingMessageGeneration(true);
        try {
            // send request to generate message by template type
            switch (template?.type) {
                case 0:
                    notificationMessage.error("Cannot generate message for hard template");
                    break;
                case 1:
                    await generateForWorkflowTemplate(template);
                    break;
                case 2:
                    // await generateForSoftTemplate(template); //deprecated
                    break;
            }
        } catch (error: any) {
            console.log(error);
            notificationMessage.error("Error generating message");
        } finally {
            setLoadingMessageGeneration(false);
        }
    };

    const generateForWorkflowTemplate = async (template: any) => {
        const { data } = await axios.post(
            `${env.REACT_APP_BACKEND_URL}/api/v1/templates/generate_message`,
            {
                workflow_name: template?.workflow_function,
                template_vars: fieldsForTemplate,
                template_string: template?.message,
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            }
        );

        console.log("data", data);
        if (data?.message) {
            setGeneratedMessage(data.message);
        }
    };

    function getFieldAutoFillValue(field: string, lead: ILead): string {
        switch (field) {
            case "first_name":
                setFieldsForTemplate((prev: any) => ({ ...prev, [field]: lead?.name?.split(" ")[0] || "" }));
                return lead?.name?.split(" ")[0] || "";
            case "last_name":
                setFieldsForTemplate((prev: any) => ({
                    ...prev,
                    [field]: (lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ").slice(1).join(" ") : "") || "",
                }));
                return (lead?.name?.split(" ").length > 1 ? lead?.name?.split(" ").slice(1).join(" ") : "") || "";

            case "company_name":
                setFieldsForTemplate((prev: any) => ({ ...prev, [field]: lead?.company_name || "" }));
                return lead?.company_name || "";
            default:
                return "";
        }
    }

    const setMessageChecked = async () => {
        axios
            .patch(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/${campaignId}/${lead?.id}`,
                { message_checked: 1 },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            )
            .then((response) => {
                console.log(response);
                // window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                notificationMessage.error("Error updating message checked");
            });
    };

    const setMessageFailedCheck = () => {
        axios
            .patch(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/${campaignId}/${lead?.id}`,
                { message_checked: 2 },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            )
            .then((response) => {
                console.log(response);
                // window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                notificationMessage.error("Error updating message checked");
            });
    };

    const handleAcceptSuggestion = async (suggestion: string) => {
        console.log("handleAcceptSuggestion -> suggestion:", suggestion);
        setMessage(suggestion);
        setMessageChecked();
        setInvokeVerification(false);
        onSubmit(suggestion);
    };

    const handleCharLimitCancel = () => {
        setIsModalOpen(false);
    };

    const handleChatLimitProceed = () => {
        setUserApproveCharLimitCancel(true);
        setIsModalOpen(false);
    };

    if (loading)
        return (
            <div>
                <Spin />
            </div>
        );

    return (
        <div>
            <h1>Edit Leads Message</h1>

            <Card title="Current Message:" bordered={false} style={{ width: "100%" }}>
                <p> {prevMessage}</p>
            </Card>

            <div
                style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <>
                    {templates.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <h3>Choose a template:</h3>
                            {templates?.length > 0 && (
                                <Select
                                    style={{ width: "100%", margin: "10px 0" }}
                                    placeholder="Select template"
                                    onChange={(id) => {
                                        // Find the selected template using the id
                                        const selectedTemplate = templates.find((template: any) => template.id === id);
                                        console.log("selectedTemplate", selectedTemplate);
                                        setChosenTemplate(selectedTemplate);
                                        handleTemplateChange(selectedTemplate?.type, templates, selectedTemplate);
                                    }}
                                >
                                    {templates.map((item: any) => {
                                        // decide class based on type
                                        let itemClass, hoverText;
                                        switch (item.type) {
                                            case 0:
                                                itemClass = "hard";
                                                hoverText = "Hard Template";
                                                break;
                                            case 1:
                                                itemClass = "link";
                                                hoverText = "Dynamic Template";
                                                break;
                                            case 2:
                                                itemClass = "soft";
                                                hoverText = "Paste Content + Template";
                                                break;
                                            default:
                                                itemClass = "default";
                                                hoverText = "Default";
                                                break;
                                        }

                                        return (
                                            <Option
                                                style={{
                                                    width: "100%",
                                                    margin: "10px 0",
                                                }}
                                                key={item?.id}
                                                value={item?.id}
                                                onMouseEnter={() => {
                                                    setHoverPlaceholder(item?.message);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverPlaceholder("");
                                                }}
                                            >
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            <span className={`color-dot-${itemClass}`}></span>
                                                            {hoverText}
                                                        </div>
                                                    }
                                                >
                                                    <span className={`color-dot-${itemClass}`}></span>
                                                </Tooltip>
                                                {item?.template_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </div>
                    )}

                    {loadingFields ? (
                        <Spin />
                    ) : (
                        <>
                            <strong>
                                <label htmlFor="fields">Required Fields for this template:</label>
                            </strong>
                            {
                                // Check if 'required_fields' is an array
                                Array.isArray(chosenTemplate?.required_fields)
                                    ? // Handle array of strings as before
                                      chosenTemplate?.required_fields?.map((field: string) => (
                                          <div key={field}>
                                              <label htmlFor={field}>{field}</label>
                                              <Input
                                                  style={{ width: "100%" }}
                                                  onChange={(e) => {
                                                      setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                  }}
                                                  placeholder={`Fill ${field}`}
                                              />
                                          </div>
                                      ))
                                    : // Handle object of fields with data type
                                      Object.entries(chosenTemplate?.required_fields || {})?.map(([field, type]) => {
                                          let InputComponent;

                                          console.log("field", field);
                                          console.log("lead", lead);

                                          switch (type) {
                                              case "string":
                                                  InputComponent = (
                                                      <Input
                                                          value={fieldsForTemplate?.[field] || getFieldAutoFillValue(field, lead) || ""}
                                                          style={{ width: "100%" }}
                                                          onChange={(e) => {
                                                              setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                          }}
                                                          placeholder={`Fill ${field}`}
                                                      />
                                                  );
                                                  break;

                                              case "date":
                                                  InputComponent = (
                                                      <DatePicker
                                                          style={{ width: "100%" }}
                                                          format="DD/MM/YY"
                                                          inputReadOnly={true}
                                                          onChange={(date: any, dateString: string) => {
                                                              setFieldsForTemplate((prev: any) => ({ ...prev, [field]: dateString }));
                                                          }}
                                                          placeholder={`Select ${field}`}
                                                      />
                                                  );
                                                  break;

                                              default:
                                                  InputComponent = (
                                                      <Input
                                                          style={{ width: "100%" }}
                                                          onChange={(e) => {
                                                              setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                          }}
                                                          placeholder={`Fill ${field}`}
                                                      />
                                                  );
                                                  break;
                                          }

                                          return (
                                              <div key={field}>
                                                  <label htmlFor={field}>{field}</label>
                                                  {InputComponent}
                                              </div>
                                          );
                                      })
                            }
                        </>
                    )}
                    <div
                        style={{
                            marginTop: "30px",
                        }}
                    >
                        <strong>
                            <label htmlFor="message">Message Editor:</label>
                        </strong>
                        <Modal
                            title="You have reached the 275 character limit"
                            open={isModalOpen}
                            footer={[
                                <Button key="procceed" onClick={handleChatLimitProceed}>
                                    It's an existing network prospect
                                </Button>,
                                <Button type="primary" key="primary" onClick={handleCharLimitCancel}>
                                    Ok
                                </Button>,
                            ]}
                        >
                            <p>The message can be longer than 275 characters only if the prospect is from an existing network</p>
                        </Modal>
                        <TextareaAutosize
                            // @ts-ignore
                            style={{ minWidth: "100%", height: "300px" }}
                            onChange={(e) => {
                                if (!userApproveCharLimitCancel && e.target.value.length >= 275) {
                                    return setIsModalOpen(true);
                                }
                                setMessage(e.target.value);
                            }}
                            defaultValue={message}
                            value={message}
                            placeholder={hoverPlaceholder || chosenTemplate?.message || "Input message here"}
                        />
                    </div>
                    {
                        // if chosen template is hard or soft, show generate message button
                        (chosenTemplate?.type === 1 || chosenTemplate?.type === 2) && (
                            <div>
                                <Button
                                    style={{ margin: "10px 0" }}
                                    onClick={() => {
                                        setGeneratedMessage("");
                                        generateMessage(chosenTemplate);
                                    }}
                                >
                                    {loadingMessageGeneration ? (
                                        <Spin
                                            // indicator={antIcon}
                                            style={{
                                                width: "100px",
                                                transition: "all 0.3s ease",
                                            }}
                                        />
                                    ) : generatedMessage ? (
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <RedoOutlined />
                                            Generate again
                                        </span>
                                    ) : (
                                        "Generate Message"
                                    )}
                                </Button>
                            </div>
                        )
                    }
                    {generatedMessage && (
                        <div>
                            <Card title="Generated Message">
                                <Alert
                                    message={
                                        <span>
                                            Please <strong>carefully</strong> check the generated message for any mistakes.
                                        </span>
                                    }
                                    type="warning"
                                    showIcon
                                    closable
                                />

                                <p style={{ margin: "10px 0" }}>{generatedMessage}</p>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "10px 0",
                                        gap: "10px",
                                    }}
                                >
                                    <Button
                                        style={{ margin: "10px 0" }}
                                        onClick={() => {
                                            setMessage(generatedMessage);
                                            setGeneratedMessage("");
                                        }}
                                    >
                                        Move to editor
                                    </Button>
                                    <Button
                                        style={{ margin: "10px 0" }}
                                        onClick={() => {
                                            setGeneratedMessage("");
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    )}
                </>

                <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                    <Button type="primary" onClick={() => onSubmit(message)}>
                        Update
                    </Button>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                setInvokeVerification(true);
                            }}
                        >
                            Run Check
                        </Button>
                        <MessageVerification
                            invokeVerification={invokeVerification}
                            message={message}
                            record={lead}
                            isEditorOpen={false}
                            handleAcceptSuggestion={handleAcceptSuggestion}
                            verifiedCallback={() => setMessageChecked()}
                            failedCallback={() => setMessageFailedCheck()}
                        />
                    </div>

                    <Button style={{ marginLeft: "10px" }} onClick={() => navigate("/campaign")}>
                        Back
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditLeadsMessage;
